import React from "react";
import Layout from "../compoents/Layout";
import SignUp from "../compoents/SignUp";

export default function SignUpRoute() {
  return (
    <Layout>
      <SignUp />
    </Layout>
  );
}
