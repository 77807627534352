import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginRoute from "./login";
import { routes } from "@/config/constants";
import SignUpRoute from "./sign-up";
import ForgotPasswordRoute from "./forgot-password";
import ResetPasswordRoute from "./reset-password";

export default function AuthRoutes() {
  return (
    <Routes>
      <Route path={routes.login} element={<LoginRoute />} />
      <Route path={routes.signup} element={<SignUpRoute />} />
      <Route path={routes.forgotPassword} element={<ForgotPasswordRoute />} />
      <Route path={routes.resetPassword} element={<ResetPasswordRoute />} />
    </Routes>
  );
}
