import Axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "@/config";
import handleErrorMessage from "@/utilities/get-error";
import getUserToken from "@/utilities/get-user-token";

function authRequestInterceptors(config: AxiosRequestConfig) {
  const token = getUserToken();
  config.headers = config.headers ?? {};
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = `application/json`;
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL
});

axios.interceptors.request.use(authRequestInterceptors as any);
axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    handleErrorMessage(error);
    return Promise.reject(error);
  }
);
