import { Box, Text } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import "./index.css";
import { RxDashboard } from "react-icons/rx";
import { TbNotes } from "react-icons/tb";
import { FiDatabase, FiLogOut } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineTag } from "react-icons/ai";
import { useRecoilState } from "recoil";
import { VendorTokenState } from "@/features/auth/resources";
import { MdNotes } from "react-icons/md";

type SideBarProps = {
  name: string;
  IconPath: any;
  path?: string;
  multiPaths?: any;
  onClick?: Function;
};

export function NavItem({
  name,
  IconPath,
  path,
  onClick,
  multiPaths
}: SideBarProps) {
  return (
    <li
      style={{ marginBottom: "0.7rem" }}
      className="nav-item"
      onClick={() => onClick && onClick()}
    >
      <>
        <NavLink
          className={({ isActive }) => {
            if (Array.isArray(multiPaths)) {
              return multiPaths?.some(path => window.location.pathname === path)
                ? "nav-it active-link"
                : "nav-it";
            } else {
              return path && isActive ? "nav-it active-link" : "nav-it";
            }
          }}
          to={path as string}
          end
        >
          {IconPath && <IconPath size={"1.52rem"} />}

          <Text w={"11rem"} textAlign={"left"}>
            {name}
          </Text>
        </NavLink>
      </>
    </li>
  );
}

export default function SideBar() {
  const [, setVendorToken] = useRecoilState(VendorTokenState);
  const navigate = useNavigate();

  function signOut() {
    setVendorToken("");
    navigate("/auth/login");
  }
  return (
    <Box
      mt={"3rem"}
      display={{ base: "none", md: "block" }}
      position="fixed"
      zIndex={"6"}
      bg="var(--keza-dashboard)"
    >
      <nav className="sb-nav-wrapper">
        <ul>
          <NavItem
            name="Dashboard"
            path="/dashboard"
            IconPath={RxDashboard}
            multiPaths={["/", "/dashboard"]}
            // onClick={handleMenuOpen}
          />
          <NavItem
            name="Product requests"
            path="requests"
            IconPath={TbNotes}
            // onClick={handleMenuOpen}
          />

          <NavItem
            name="My inventory"
            path="inventory"
            IconPath={AiOutlineTag}
            // onClick={handleMenuOpen}
          />

          <NavItem
            name="Applications"
            path="applications"
            IconPath={MdNotes}
            // onClick={handleMenuOpen}
          />

          <NavItem
            name="Profile"
            path="profile"
            IconPath={FaRegUser}
            // onClick={handleMenuOpen}
          />

          <NavItem
            name="Uploads"
            path="uploads"
            IconPath={FiDatabase}
            // onClick={handleMenuOpen}
          />

          {/* 

          <NavItem
            name="Profile"
            path="profile"
            IconPath={FaRegUser}
            // onClick={handleMenuOpen}
          /> */}
        </ul>
      </nav>

      <br />

      <nav className="so-nav">
        <ul>
          <NavItem onClick={signOut} name="Sign Out" IconPath={FiLogOut} />
        </ul>
      </nav>
    </Box>
  );
}
