import { CustomModalProps } from "./UploadCac";
import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { TextInput } from "../Input";
import { useFormik } from "formik";
import * as yup from "yup";
import { useUpdateVendor } from "@/features/dashboard/operations";
import handleErrorMessage from "@/utilities/get-error";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { VendorState } from "@/features/auth/resources";

export default function RcNumberModal({ isOpen, onClose }: CustomModalProps) {
  const [vendor, setVendor] = useRecoilState(VendorState);

  const { updateVendor, updateVendorResult } = useUpdateVendor();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: { businessNumber: vendor.business.number },
      validationSchema: yup.object().shape({
        businessNumber: yup
          .string()
          .required("Please provide your Business Number")
          .test(
            "Business Number Validation",
            "Please provide a valid Business Number",
            value => new RegExp("RC|BN").test(value)
          )
      }),
      onSubmit: async values => {
        try {
          const data = (await updateVendor(values)) as any;

          if (data) {
            setVendor(data.data);
            toast(data?.message, { type: "success" });
          }
        } catch (error) {
          handleErrorMessage(error);
        }
      }
    });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={["95%", ""]}>
        <ModalCloseButton />
        <ModalBody p={{ base: "2rem 1rem", md: "3rem 2rem" }}>
          <Text fontWeight={"600"} color={"var(--keza-brown)"}>
            Edit RC / BN number
          </Text>
          <Text fontSize={"15"} opacity="0.5">
            RC/BN number
          </Text>

          <Box my={"4rem"}>
            <TextInput
              label="Business number"
              name="businessNumber"
              onChange={handleChange}
              value={values.businessNumber}
              isInvalid={!!errors.businessNumber}
              error={!!errors.businessNumber}
              onBlur={handleBlur}
              touched={touched.businessNumber}
              errorMessage={errors.businessNumber}
            />
          </Box>

          <Center>
            <Button
              colorScheme={"var(keza-brown)"}
              onClick={() => handleSubmit()}
              color={"white"}
              bg="var(--keza-brown)"
              isLoading={updateVendorResult.isLoading}
            >
              Save changes
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
