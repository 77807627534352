import { PasswordInput, TextInput } from "@/components/Input";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  Link,
  Stack,
  Text
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { VendorForgotPassword } from "../types";
import * as Yup from "yup";
import { useVendorForgotPassword } from "../operations";
import { useRecoilState } from "recoil";
import { VendorState, VendorTokenState } from "../resources";
import { Navigate, useNavigate, NavLink } from "react-router-dom";
import { isExpired } from "@/utilities/get-user-token";
import handleErrorMessage from "@/utilities/get-error";
import { toast } from "react-toastify";

export default function Login() {
  const { forgotPasswordVendor, forgotPasswordVendorResult } = useVendorForgotPassword();
  const [token, setVendorToken] = useRecoilState(VendorTokenState);
  const navigate = useNavigate();

  const { handleChange, values, errors, submitForm } = useFormik<VendorForgotPassword>({
    initialValues: {
      email: "",
    },
    onSubmit: async () => {
      try {
        const { email } = values;
        await forgotPasswordVendor(email);
        toast('An reset instruction has been sent to your email address', { type: 'success' });
      } catch (err) {
        handleErrorMessage(err);
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required(),
    })
  });

  if (!isExpired() && token) {
    // this should navigate to where the vendor is coming from
    return <Navigate to={"/dashboard"} />;
  } else {
    return (
      <Box>
        <Text
          fontWeight={"700"}
          fontSize={{ base: "16px", md: "18px" }}
          color={"var(--keza-brown)"}
        >
         Forgot Password!
        </Text>
        <Text fontSize={{ base: "12px", md: "14px" }}>
          Enter the email address you registered with
        </Text>

        <Divider borderColor={"black"} opacity="0.4" my={"2rem"} />

        <FormControl>
          <Box maxW={{ base: "100%", md: "80%", lg: "70%" }}>
            <TextInput
              name="email"
              onChange={handleChange}
              label={"Email address"}
              isInvalid={Boolean(errors.email)}
              value={values.email}
            />
          </Box>
          <Box
            mt={{ base: "1.5rem", md: "2rem" }}
            maxW={{ base: "100%", md: "80%", lg: "70%" }}
          >
            <Text
              mt={"01rem"}
              cursor="pointer"
              textAlign={"right"}
              fontSize={"14px"}
              textDecoration="underline"
              color={"var(--keza-brown)"}
            >
              <NavLink className={"small-brwn-form"}
                color={"var(--keza-brown)"} to="/auth/login">
                Go back to login?
              </NavLink>
            </Text>
          </Box>
        </FormControl>
        <Center
          as={Stack}
          mt={"4rem"}
          maxW={{ base: "100%", md: "80%", lg: "60%" }}
        >
          <Box>
            <Button
              color="white"
              bg="var(--keza-brown)"
              size={"lg"}
              _hover={{
                bg: "rgba(29, 29, 29, 0.1);"
              }}
              w="15rem"
              mb={"1rem"}
              onClick={submitForm}
              isLoading={forgotPasswordVendorResult.isLoading}
            >
              Request Reset
            </Button>
          </Box>
        </Center>
      </Box>
    );
  }
}
