import { useAddVendorPricing } from "@/features/dashboard/operations";
import { Product, VendorPricing } from "@/interfaces/product.interface";
import { formatValue } from "@/utilities/format-comma-input";
import handleErrorMessage from "@/utilities/get-error";
import {
  Button,
  Center,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { omit } from "lodash";
import React from "react";
import { toast } from "react-toastify";
import { RequestItem } from "../DashboardComponents/PlaceOfferModal";
import { TextInput } from "../Input";

const AddPricingModal = ({
  isOpen,
  onClose,
  setSuccess,
  addedProduct = null,
  vendorPricing = null
}: {
  isOpen: boolean;
  onClose: () => any;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  vendorPricing: VendorPricing | null;
  addedProduct?: Product | null;
}) => {
  const product = addedProduct || vendorPricing?.product;

  const { values, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      pricing: (product?.pricing || []).map(price => ({
        storageROM: price.storageROM,
        storageRAM: price.storageRAM,
        price:
          (vendorPricing?.pricing || []).find(
            vendorPrice =>
              vendorPrice.storageRAM === price.storageRAM &&
              vendorPrice.storageROM === price.storageROM
          )?.priceNew || "0"
      }))
    },
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        const response = await addVendorPricing({
          product: product?._id,
          pricing: values.pricing.map(price => ({
            ...omit(price, ["price"]),
            priceNew: price.price,
            priceOld: price.price
          }))
        });

        if (response?.data) {
          toast("Successfully saved pricing for this product", {
            type: "success"
          });
          setSuccess(true);
          handleClose();
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    }
  });

  const { addVendorPricing, addVendorPricingResult } = useAddVendorPricing();

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent w={"full"}>
        <ModalCloseButton />
        <ModalBody p={"3rem 2rem"}>
          <Text color={"var(--keza-brown)"} fontWeight={"700"}>
            {`Add ${product?.name || "a new device"} to your shelf`}
          </Text>
          <Text fontSize={["14px", ""]} opacity="0.5">
            Select the device details below
          </Text>
          <Center
            fontWeight={"600"}
            fontSize="16px"
            mt="2rem"
            w={"10rem"}
            bg={"#FFF2DF"}
            p="0.2rem"
          >
            Device details
          </Center>
          <Flex direction={"column"} gap={"1rem"} mt={"2rem"}>
            <RequestItem
              name={"Colors: "}
              value={product?.colors?.join(", ") || "-"}
            />
            <RequestItem
              name={"Condition: "}
              value={
                product?.condition?.split("_")?.join(" ")?.toLowerCase() || "-"
              }
            />
            <RequestItem
              name={"Chipset:"}
              value={product?.component?.chip || "-"}
            />
          </Flex>

          <Center
            fontWeight={"600"}
            fontSize="16px"
            m="2rem 0"
            w={"10rem"}
            bg={"#FFF2DF"}
            p="0.2rem"
          >
            Pricing
          </Center>
          {values.pricing.map((price, index) => (
            <React.Fragment key={index}>
              <Stack m={"2rem 0"} direction={["column", "row"]} gap={"10px"}>
                <TextInput
                  label="ROM"
                  extraInputProps={{
                    isReadOnly: true
                  }}
                  onChange={() => {}}
                  name={`pricing.${index}.storageROM`}
                  value={price.storageROM}
                  boxProps={{
                    w: { base: "50%", md: "25%" }
                  }}
                />
                <TextInput
                  label="RAM"
                  extraInputProps={{
                    isReadOnly: true
                  }}
                  onChange={() => {}}
                  name={`pricing.${index}.storageRAM`}
                  value={price.storageRAM}
                  boxProps={{
                    w: "25%"
                  }}
                />
                <TextInput
                  label="Your Price"
                  onChange={event => {
                    const { value, name } = event.target;
                    if (!(value && value.length)) {
                      handleChange({ target: { name, value: "" } });
                    } else if (!isNaN(Number(value.split(",").join("")))) {
                      const itemValue = parseInt(value.split(",").join(""), 10);
                      handleChange({
                        target: { name, value: String(itemValue) }
                      });
                    }
                  }}
                  name={`pricing.${index}.price`}
                  value={`${formatValue(price.price || "0", false)}`}
                />
              </Stack>
              {index < values.pricing.length - 1 && (
                <Divider
                  opacity={"1"}
                  color={"blackAlpha.900"}
                  borderBottomWidth={"5px"}
                />
              )}
            </React.Fragment>
          ))}
          <Center fontSize={"16px"} my={"1rem"} gap="1rem">
            <Button
              color={"white"}
              bg="var(--keza-brown)"
              _hover={{
                bg: "var(--keza-brown)"
              }}
              onClick={() => handleSubmit()}
              isLoading={addVendorPricingResult.isLoading}
            >
              Save
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddPricingModal;
