import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import SectionHeader from "./SectionHeader";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import PersonalDetails from "@/components/ProfileComponents/PersonalDetails";
import BusinessInfo from "@/components/ProfileComponents/BusinessInfo";
import { useRecoilState, useRecoilValue } from "recoil";
import { VendorState } from "@/features/auth/resources";
import { ProductRequestState } from "../resources/vendor";
import VendorSettings from "@/components/ProfileComponents/VendorSettings";

export default function Profile() {
  const vendor = useRecoilValue(VendorState);
  const [productRequests] = useRecoilState(ProductRequestState);

  return (
    <div className="dash-child">
      <SectionHeader />
      <Text fontWeight="600" fontSize={["16px", "20px"]}>
        Profile
      </Text>

      <Flex
        alignItems={["center", "flex-end"]}
        gap={["1rem", "2rem"]}
        mt={"2rem"}
        direction={["column", "row"]}
      >
        <Center
          bg="#F3F3F3"
          borderRadius="1000px"
          w={["6rem", "6rem"]}
          h={["6rem", "6rem"]}
        >
          <Image
            w={"4rem"}
            h="4rem"
            borderRadius={"50%"}
            src={vendor.business.logo}
            alt="avatar"
          />
        </Center>
        <Flex direction={"column"}>
          <Text
            textTransform={"capitalize"}
            fontWeight={"600"}
            fontSize={["16px"]}
          >
            {vendor?.business?.name?.toLowerCase()}
          </Text>
        </Flex>
        <Flex fontSize={"14px"} gap="1rem">
          <Text opacity={"0.5"}>Vendor status:</Text>
          <Flex
            gap={"0.5rem"}
            color={vendor?.isActive ? "#069C27" : "#E9A625"}
            alignItems={"center"}
          >
            <Text>{vendor?.isActive ? "Approved" : "Pending"}</Text>
            <IoIosCheckmarkCircleOutline />
          </Flex>
        </Flex>

        <Flex fontSize={"14px"} gap="1rem">
          <Text opacity={"0.5"}>Total phones sold::</Text>
          <Text>
            {
              (productRequests?.content || [])?.filter(
                request => !!request.isSold
              ).length
            }
          </Text>
        </Flex>
      </Flex>

      <Box
        mt={["1rem", "2rem"]}
        borderRadius={"10px"}
        p={["0rem", "3rem"]}
        border={["", "0.6px solid rgba(29, 29, 29, 0.5)"]}
        w={{ base: "100%", md: "60%" }}
      >
        <Box pt={"1rem"}>
          <PersonalDetails />
          <BusinessInfo />
          <VendorSettings />
          {/*  <IdentityVerification />
          <Box w="100%" my={"1rem"} h="1px" opacity={"0.15"} bg="black"></Box>
          <EmploymentAnfFin noVBtn={true} />
          <Box w="100%" h="1px" my={"1rem"} opacity={"0.15"} bg="black"></Box>
          <ChangePassword />
          <Box w="100%" h="1px" my={"1rem"} opacity={"0.15"} bg="black"></Box> */}
        </Box>
      </Box>
    </div>
  );
}
