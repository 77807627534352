import { PasswordInput, TextInput } from "@/components/Input";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  Link,
  Stack,
  Text
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { VendorResetPassword } from "../types";
import * as Yup from "yup";
import { useVendorResetPassword } from "../operations";
import { useRecoilState } from "recoil";
import { VendorState, VendorTokenState } from "../resources";
import { Navigate, useNavigate, NavLink, useSearchParams } from "react-router-dom";
import { isExpired } from "@/utilities/get-user-token";
import handleErrorMessage from "@/utilities/get-error";
import { toast } from "react-toastify";

export default function Login() {
  const { resetPasswordVendor, resetPasswordVendorResult } = useVendorResetPassword();
  const [token, setVendorToken] = useRecoilState(VendorTokenState);
  const [, setVendorState] = useRecoilState(VendorState);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { handleChange, values, errors, submitForm } = useFormik<VendorResetPassword>({
    initialValues: {
      newPassword: "",
      confirmNewPassword: ""
    },
    onSubmit: async () => {
      try {
        if (!searchParams.get('token')) {
            throw Error ('Invalid token, token cannot be empty')
        }
        const { newPassword } = values;
        await resetPasswordVendor({ token: searchParams.get('token') || '', newPassword });
        toast('Password has been reset successfully', { type: 'success' });
        navigate("/auth/login");
      } catch (err) {
        handleErrorMessage(err);
      }
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Password is required"),
      confirmNewPassword: Yup
        .string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
    }),
  });
  if (!isExpired() && token) {
    // this should navigate to where the vendor is coming from
    return <Navigate to={"/dashboard"} />;
  } else {
    return (
      <Box>
        <Text
          fontWeight={"700"}
          fontSize={{ base: "16px", md: "18px" }}
          color={"var(--keza-brown)"}
        >
          Welcome back!
        </Text>
        <Text fontSize={{ base: "12px", md: "14px" }}>
          Enter your details correctly to sign in to your dashboard
        </Text>

        <Divider borderColor={"black"} opacity="0.4" my={"2rem"} />

        <FormControl>
          <Box maxW={{ base: "100%", md: "80%", lg: "70%" }}>
            <PasswordInput
              name="newPassword"
              onChange={handleChange}
              label={"New Password"}
              isInvalid={Boolean(errors.newPassword)}
              value={values.newPassword}
            />
          </Box>

          <Box
            mt={{ base: "1.5rem", md: "2rem" }}
            maxW={{ base: "100%", md: "80%", lg: "70%" }}
          >
            <PasswordInput
              name="confirmNewPassword"
              onChange={handleChange}
              label={"Confirm New Password"}
              isInvalid={Boolean(errors.confirmNewPassword)}
              value={values.confirmNewPassword}
            />
            {errors.confirmNewPassword && (
                  <small className="small-txt">{errors?.confirmNewPassword}</small>
                )}
            <Text
              mt={"01rem"}
              cursor="pointer"
              textAlign={"right"}
              fontSize={"14px"}
              textDecoration="underline"
              color={"var(--keza-brown)"}
            >
              <Text
              mt={"01rem"}
              cursor="pointer"
              textAlign={"right"}
              fontSize={"14px"}
              textDecoration="underline"
              color={"var(--keza-brown)"}
            >
              <NavLink className={"small-brwn-form"}
                color={"var(--keza-brown)"} to="/auth/login">
                Go back to login?
              </NavLink>
            </Text>
            </Text>
          </Box>
        </FormControl>
        <Center
          as={Stack}
          mt={"4rem"}
          maxW={{ base: "100%", md: "80%", lg: "60%" }}
        >
          <Box>
            <Button
              color="white"
              bg="var(--keza-brown)"
              size={"lg"}
              _hover={{
                bg: "rgba(29, 29, 29, 0.1);"
              }}
              w="15rem"
              mb={"1rem"}
              onClick={submitForm}
              isLoading={resetPasswordVendorResult.isLoading}
            >
              Reset Password
            </Button>
          </Box>
          <Flex gap="1rem" fontSize={"14px"} mb="3rem">
            <Text opacity={"0.7"}>I don’t have an account</Text>
            <Link
              href="/auth/sign-up"
              cursor={"pointer"}
              fontWeight={"600"}
              color="var(--keza-brown)"
            >
              Create an account
            </Link>
          </Flex>
        </Center>
      </Box>
    );
  }
}