import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import SectionHeader from "./SectionHeader";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import pdfLogo from "@/assets/dashboard/pdf.png";
import UploadCac from "@/components/UploadComponents/UploadCac";
import RcNumberModal from "@/components/UploadComponents/RcNumber";
import UploadPicture from "@/components/UploadComponents/UploadPicture";
import { useRecoilState, useRecoilValue } from "recoil";
import { VendorState } from "@/features/auth/resources";
import { ProductRequestState } from "../resources/vendor";
import UploadLogo from "@/components/UploadComponents/UploadLogo";
import ChangeUsername from "@/components/UploadComponents/ChangeUsername";

type UploadItemProps = {
  name: string;
  warning?: string;
  wdesc?: string;
  pdfDetails?: { name: string; logo: string };
  onClick?: () => void;
};
function UploadItem({
  name,
  warning,
  wdesc,
  pdfDetails,
  onClick
}: UploadItemProps) {
  return (
    <Flex alignItems={"center"} justifyContent={"space-between"}>
      <Box>
        <Text
          mb={"1rem"}
          fontWeight={"700"}
          fontSize={{ base: "14px", md: "16px" }}
        >
          {name}
        </Text>
        {pdfDetails ? (
          <Flex alignItems={"center"} gap="1rem">
            <Box maxW={"2.3rem"}>
              <img src={pdfDetails.logo} alt="" />
            </Box>
            <Text opacity={"0.8"} fontSize="15px">
              {pdfDetails.name}
            </Text>
          </Flex>
        ) : (
          <Box>
            <Text fontSize={"15px"} color={"red"}>
              {warning}
            </Text>
            <Text fontSize={"12px"} opacity="0.7">
              {wdesc}
            </Text>
          </Box>
        )}
      </Box>
      <Box>
        <Button
          borderColor={"var(--keza-brown)"}
          w={{ base: "6rem", md: "9rem" }}
          h={{ base: "2rem", md: "" }}
          fontSize={{ base: "12px", md: "" }}
          color={"var(--keza-brown)"}
          variant={"light"}
          size="sm"
          onClick={onClick}
        >
          View
        </Button>
      </Box>
    </Flex>
  );
}
export default function Uploads() {
  const cacDisclosure = useDisclosure();
  const rcDisclosure = useDisclosure();
  const usernameDisclosure = useDisclosure();
  const upDisclosure = useDisclosure();
  const logoDisclosure = useDisclosure();

  const vendor = useRecoilValue(VendorState);
  const [productRequests] = useRecoilState(ProductRequestState);

  return (
    <div className="dash-child">
      <SectionHeader />
      <Text fontWeight="600" fontSize={["16px", "20px"]}>
        Profile
      </Text>

      <Flex
        alignItems={["center", "flex-end"]}
        gap={["1rem", "2rem"]}
        mt={"2rem"}
        direction={["column", "row"]}
      >
        <Center
          bg="#F3F3F3"
          borderRadius="1000px"
          w={["6rem", "6rem"]}
          h={["6rem", "6rem"]}
        >
          <Image
            w={"4rem"}
            h="4rem"
            borderRadius={"50%"}
            src={vendor.business.logo}
            alt="avatar"
          />
        </Center>
        <Flex direction={"column"}>
          <Text
            textTransform={"capitalize"}
            fontWeight={"600"}
            fontSize={["16px"]}
          >
            {vendor?.business?.name?.toLowerCase()}{" "}
          </Text>
        </Flex>
        <Flex fontSize={"14px"} gap="1rem">
          <Text opacity={"0.5"}>Vendor status:</Text>
          <Flex
            gap={"0.5rem"}
            color={vendor?.isActive ? "#069C27" : "#E9A625"}
            alignItems={"center"}
          >
            <Text>{vendor?.isActive ? "Approved" : "Pending"}</Text>
            <IoIosCheckmarkCircleOutline />
          </Flex>
        </Flex>

        <Flex fontSize={"14px"} gap="1rem">
          <Text opacity={"0.5"}>Total phones sold::</Text>
          <Text>
            {
              productRequests?.content?.filter(request => !!request.isSold)
                .length
            }
          </Text>
        </Flex>
      </Flex>

      <Box
        mt={{ base: "1rem", md: "2rem" }}
        borderRadius={"10px"}
        p={{ base: "0rem", md: "1.5rem" }}
        border={{ base: "", md: "0.6px solid rgba(29, 29, 29, 0.5)" }}
        w={{ base: "100%", md: "80%", xl: "60%" }}
      >
        <Text fontWeight={"700"} fontSize={{ base: "16px", md: "" }}>
          My uploads
        </Text>
        <Text fontSize={"15px"} opacity="0.5">
          Upload or view your business documents
        </Text>
        <Box w="100%" my={"1rem"} h="1px" opacity={"0.15"} bg="black"></Box>

        <Flex direction={"column"} gap="2.3rem" pt={"1rem"}>
          <UploadItem
            name="Business number"
            wdesc={vendor.business.number}
            onClick={() => rcDisclosure.onOpen()}
          />
          <UploadItem
            name="Username"
            wdesc={vendor.username}
            onClick={() => usernameDisclosure.onOpen()}
          />
          <UploadItem
            name="CAC document"
            warning={!vendor.business.compliance ? "Document required!" : ""}
            wdesc={
              vendor.business.compliance
                ? "Uploaded"
                : "File type should be pdf"
            }
            onClick={() => cacDisclosure.onOpen()}
          />

          <UploadItem
            name="Picture of you standing in front of your shop"
            pdfDetails={{
              logo: vendor.business.photo ? pdfLogo : "",
              name: vendor.business.photo ? "Uploaded" : ""
            }}
            onClick={() => upDisclosure.onOpen()}
          />

          <UploadItem
            name="Your Business Logo"
            pdfDetails={{
              logo: vendor.business.logo ? pdfLogo : "",
              name: vendor.business.logo
                ? "Uploaded"
                : "Please add your business logo here"
            }}
            onClick={() => logoDisclosure.onOpen()}
          />
        </Flex>
      </Box>

      <UploadCac
        onClose={cacDisclosure.onClose}
        isOpen={cacDisclosure.isOpen}
      />
      <RcNumberModal
        onClose={rcDisclosure.onClose}
        isOpen={rcDisclosure.isOpen}
      />
      <ChangeUsername
        onClose={usernameDisclosure.onClose}
        isOpen={usernameDisclosure.isOpen}
      />
      <UploadPicture
        onClose={upDisclosure.onClose}
        isOpen={upDisclosure.isOpen}
      />
      <UploadLogo
        onClose={logoDisclosure.onClose}
        isOpen={logoDisclosure.isOpen}
      />
    </div>
  );
}
