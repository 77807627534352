import { atom } from "recoil";
import { RequestsResponse } from "../types";

export const ProductRequestState = atom<RequestsResponse>({
  key: "ProductRequestState",
  default: {
    content: [],
    pagination: {
      page: 1,
      size: 1,
      totalDocs: 1,
      numberOfPages: 1
    }
  }
});
