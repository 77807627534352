import { axios } from "@/lib/axios";
import { VendorLogin, VendorRegister, VendorRegisterResponse } from "../types";

export function getVendor(id: string) {
  return axios.get(`/vendors/get-vendor/${id}`);
}

export function registerVendor(
  data: VendorRegister
): Promise<Partial<VendorRegister>> {
  return axios.post("/vendors/create-vendor", data);
}

export function loginVendor(
  data: VendorLogin
): Promise<VendorRegisterResponse> {
  return axios.post("/vendors/login-vendor", data);
}

export function forgotPasswordVendor(email: string) {
  return axios.post("/vendors/auth/forgot-password", { email });
}

export function resetPasswordVendor(
  payload: { token: string, newPassword: string }
): Promise<{ message: string }> {
  return axios.post("/vendors/auth/reset-password-with-token", { ...payload });
}

export function resendVerifyEmailLinkVendor(email: string) {
  return axios.post("/vendors/auth/resend-verify", { email });
}
