import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import { CustomModalProps } from "./UploadCac";
import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useUploadImage } from "@/features/dashboard/operations";
import { useRecoilState } from "recoil";
import { VendorState } from "@/features/auth/resources";
import { toast } from "react-toastify";

export const videoConstraints = {
  width: 230,
  height: 200,
  facingMode: "user"
};

export default function UploadPicture({ isOpen, onClose }: CustomModalProps) {
  const [, setVendor] = useRecoilState(VendorState);
  const { uploadImage, uploadImageResult } = useUploadImage();
  const webCamRef = useRef<HTMLInputElement | null>(null);
  const [webCamOpen, setWebCamOpen] = useState(false);
  const [selfieImage, setSelfImage] = useState<any>();
  const [isImageConfirmed, setIsImageConfirmed] = useState(false);

  function capture() {
    const cur = webCamRef.current as any;
    const pictureSrc: any = cur.getScreenshot();
    setSelfImage(pictureSrc);
  }

  const handleUploadImage = async () => {
    const response = await fetch(selfieImage);

    const blob = await response.blob();

    const image = new File([blob], `Vendor Selfie`, { type: "image/jpeg" });

    const form = new FormData();
    form.append("image", image, image.name);

    const data = await uploadImage(form);

    if (data) {
      setVendor(data.data);
      setIsImageConfirmed(false);
      toast((data as any).message, { type: "success" });
    }
  };

  useEffect(() => {
    if (isImageConfirmed) {
      handleUploadImage();
    }
    // eslint-disable-next-line
  }, [isImageConfirmed]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={["95%", ""]}>
        <ModalCloseButton />
        <ModalBody p={{ base: "2rem 1rem", md: "3rem 2rem" }}>
          <Heading fontSize={"16px"} color={"var(--keza-brown)"} mt="2rem">
            Upload a picture
          </Heading>
          <Text fontSize="11px" mt={"1.2rem"} opacity="0.5">
            Using your selfie camera, take a clear portrait picture of your face
          </Text>
          <Box w={"full"}>
            <FormLabel>Upload Your Picture</FormLabel>
            <Box
              className="img-btn"
              py="3rem"
              h="50vh !important"
              onClick={() => setWebCamOpen(true)}
            >
              {!webCamOpen && (
                <Center
                  gap={["1rem", "2rem"]}
                  p={"2rem"}
                  border="0.663653px solid rgba(29, 29, 29)"
                  cursor={"pointer"}
                >
                  <AiOutlineCloudUpload size={"2rem"} />
                  <Text fontSize={["14px", ""]}>
                    Click here to upload a new picture
                  </Text>
                </Center>
              )}

              {webCamOpen && (
                <Box>
                  {selfieImage && (
                    <Center>
                      <img src={selfieImage} alt="shop-pictures" />
                    </Center>
                  )}

                  {!selfieImage && (
                    <Center>
                      <Webcam
                        audio={false}
                        height={200}
                        ref={webCamRef as any}
                        screenshotFormat="image/jpeg"
                        width={220}
                        videoConstraints={videoConstraints}
                      />
                    </Center>
                  )}

                  <Center mt="1.5rem" gap={1} w="100%">
                    {!isImageConfirmed && (
                      <Flex gap={"2rem"} justifyContent="space-between">
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            if (selfieImage) {
                              setIsImageConfirmed(true);
                            } else {
                              capture();
                            }
                          }}
                          bg="var(--keza-brown)"
                          color={"white"}
                          w="5rem"
                          h={"2rem"}
                          fontSize="14px"
                          borderRadius={"6px"}
                          isLoading={uploadImageResult.isLoading}
                        >
                          {Boolean(selfieImage) ? "Upload" : "Take Shot"}
                        </Button>

                        {selfieImage && (
                          <Button
                            onClick={e => {
                              setSelfImage("");
                            }}
                            className="form-btn"
                            bg="var(--keza-brown)"
                            color={"white"}
                            w="5rem"
                            h={"2rem"}
                            fontSize="14px"
                            borderRadius={"6px"}
                          >
                            Retake
                          </Button>
                        )}
                      </Flex>
                    )}

                    {isImageConfirmed && (
                      <Center color={"green"}>
                        <IoIosCheckmarkCircleOutline />
                        <Text>Confirmed</Text>
                      </Center>
                    )}
                  </Center>
                </Box>
              )}
            </Box>
          </Box>
          {/* <VStack mb={"1rem"}>
            <Flex gap={1}>
              <Button
                bg={"var(--keza-brown)"}
                color="white"
                px={"1rem"}
                onClick={e => {
                  e.preventDefault();
                  if (selfieImage) {
                    uploadPicture();
                  } else {
                    capture();
                  }
                }}
                className="form-btn"
              >
                {selfieImage ? "Upload" : "Take Shot"}
              </Button>
            </Flex>
          </VStack> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
