import { VendorTokenState } from "@/features/auth/resources";
import { isExpired } from "@/utilities/get-user-token";
import { Navigate, Route, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import Dashboard from "../components/Dashboard";
import Layout from "../components/Layout";
import Profile from "../components/Profile";
import Request from "../components/Request";
import Uploads from "../components/Uploads";
import { useEffect } from "react";
import { useGetVendor } from "../operations";
import Progress from "@/components/Progress";
import Inventory from "../components/Inventory";
import Applications from "../components/Applications";

export default function DashboardRoute() {
  const { getVendor, getVendorResult } = useGetVendor();

  const [token] = useRecoilState(VendorTokenState);

  useEffect(() => {
    if (token) {
      getVendor();
    }
    // eslint-disable-next-line
  }, []);

  if (isExpired() || !token) {
    return <Navigate to={"/auth/login"} />;
  } else {
    return (
      <>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="requests" element={<Request />} />
            <Route path="inventory" element={<Inventory />} />
            <Route path="profile" element={<Profile />} />
            <Route path="uploads" element={<Uploads />} />
            <Route path="applications" element={<Applications />} />
          </Route>
        </Routes>
        <Progress loading={getVendorResult.isLoading} />
      </>
    );
  }
}
