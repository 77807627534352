import { atom } from "recoil";
import implementPersist from "@/utilities/implement-persist";
import { VendorRegisterAtom, VendorType } from "../types";

export const VendorSignUpState = atom<VendorRegisterAtom>({
  key: "VendorSignUpState",
  default: {
    personal: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: ""
    },
    business: {
      businessName: "",
      businessAddress: "",
      yearsInBusiness: 0
    }
  }
});

export const VendorTokenState = atom({
  key: "VendorTokenState",
  default: "",
  effects_UNSTABLE: implementPersist("VendorTokenState")
});

export const VendorState = atom<VendorType>({
  key: "VendorState",
  default: {
    business: {
      logo: "",
      name: "",
      photo: "",
      number: "",
      address: "",
      compliance: "",
      yearsInBusiness: 0
    },
    createdAt: "",
    email: "",
    firstName: "",
    accountName: "",
    accountNumber:'',
    bankName:'',
    isActive: false,
    lastName: "",
    phoneNumber: "",
    rcNumber: "",
    settings: { deviceConditions: [], deviceModels: [] },
    updatedAt: "",
    username: "",
    _id: ""
  },
  effects_UNSTABLE: implementPersist("VendorState")
});
