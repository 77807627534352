import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { useState, createContext } from "react";
import ThemeProvider from "@/theme";
import { ToastContainer } from "react-toastify";

type AppProviderProps = {
  children: React.ReactNode;
};

export const MenuContext = createContext<boolean | any>(null);

export default function AppProvider({ children }: AppProviderProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter>
          <MenuContext.Provider value={[menuOpen, setMenuOpen]}>
            <ThemeProvider>{children}</ThemeProvider>
            <ToastContainer />
          </MenuContext.Provider>
        </BrowserRouter>
      </RecoilRoot>
    </QueryClientProvider>
  );
}
