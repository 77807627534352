import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import { SlCloudUpload } from "react-icons/sl";
import { useUploadLogo } from "@/features/dashboard/operations";
import handleErrorMessage from "@/utilities/get-error";
import { useRecoilState } from "recoil";
import { VendorState } from "@/features/auth/resources";
import { toast } from "react-toastify";

export type CustomModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function UploadLogo({ isOpen, onClose }: CustomModalProps) {
  const [, setVendor] = useRecoilState(VendorState);
  const { uploadLogo, uploadLogoResult } = useUploadLogo();

  const [logo, setLogo] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  async function uploadRawLogo(e: any) {
    if (logo) {
      try {
        const form = new FormData();

        form.append("logo", logo, logo.name);

        const data = await uploadLogo(form);

        if (data) {
          setVendor(data.data);
          toast((data as any).message, { type: "success" });
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={["95%", ""]}>
        <ModalCloseButton />
        <ModalBody p={{ base: "2rem 1rem", md: "3rem 2rem" }}>
          <Text fontWeight={"700"} color={"var(--keza-brown)"}>
            Upload your Business logo
          </Text>
          <Text mt="0.5rem" mb={"3rem"} opacity={"0.5"} fontSize="15px">
            Your Business logo will be shown on your customized link to your
            customers
          </Text>
          <Center cursor={"pointer"}>
            <form>
              <div>
                <Center
                  flexDirection={"column"}
                  borderRadius="8px"
                  gap={"1.5rem"}
                  border="0.663653px solid rgba(29, 29, 29)"
                  opacity={"0.5"}
                  w="20rem"
                  h={"10rem"}
                  onClick={() => {
                    if (fileInputRef?.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  <Flex alignItems={"center"} gap="1.5rem">
                    <input
                      type="file"
                      ref={fileInputRef}
                      hidden
                      accept="jpg,png,jpeg,webp,gif"
                      onChange={e => {
                        if (e.target.files?.length && e.target.files?.[0]) {
                          setLogo(e.target?.files[0] as File);
                        }
                      }}
                    />
                    <Box maxW={"2rem"}>
                      <SlCloudUpload />
                    </Box>
                    <p className="cam-p">
                      {logo ? `${logo.name}` : "Attach a logo"}
                    </p>
                  </Flex>
                </Center>
              </div>
              <Flex alignItems={"center"} justifyContent={"center"} mt={"1rem"}>
                <Button
                  isLoading={uploadLogoResult.isLoading}
                  id="auth-btn"
                  onClick={uploadRawLogo}
                  className="form-btn brwn-btn"
                >
                  Upload
                </Button>
              </Flex>
            </form>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
