import { Pricing } from "@/interfaces/product.interface";
import { axios } from "@/lib/axios";
import { BidType, RequestsResponse, UpdateVendorDto } from "../types";
import { ResolveAccount } from "@/common/types/paystack";

export function getRequests(): Promise<RequestsResponse> {
  return axios.get("/product-requests/vendor");
}

export function placeOffer(data: BidType) {
  return axios.post("product-requests/place-offer", data);
}

export async function updateVendor(data: UpdateVendorDto) {
  return axios.put(`/vendors/update-vendor`, data);
}

export async function updateVendorUsername(username = "") {
  if (username) {
    return axios.put(`/vendors/update-username`, { username });
  }
}

export async function getVendor() {
  return axios.get(`/vendors/get-vendor`);
}

export const resolveAccount = (data: ResolveAccount) => {
  return axios.post(`/vendors/resolve-account`, data);
};

export async function uploadDocument(form: FormData) {
  return axios.post(`/vendors/upload-vendor-document`, form);
}

export async function uploadImage(form: FormData) {
  return axios.post(`/vendors/upload-vendor-image`, form);
}

export async function uploadLogo(form: FormData) {
  return axios.post(`/vendors/upload-vendor-logo`, form);
}

export async function getProducts({ page = 1, size = 12, search = "" }) {
  return axios.post(`product/get-all/filter?page=${page}&size=${size}`, {
    search
  });
}

export async function getVendorPricing({ username = "", page = 1, size = 12 }) {
  if (username) {
    return axios.get(
      `vendor-pricing/${username}?page=${page - 1}&size=${size}`
    );
  }
}

export async function getVendorApplications({ page = 1, size = 10 }) {
  return axios.get(`vendors/applications?page=${page - 1}&size=${size}`);
}

export async function addVendorPricing(input: {
  product?: string;
  pricing?: Pricing[];
}) {
  if (input.product && input.pricing) {
    return axios.post(`vendor-pricing/add-vendor-pricing`, input);
  }
}

export async function removeVendorPricing(id = "") {
  if (id) {
    return axios.delete(`vendor-pricing/remove-vendor-pricing/${id}`);
  }
}

export async function uploadBulkPricing(input: FormData) {
  if (input) {
    return axios.post(`vendor-pricing/upload-bulk-pricing`, input);
  }
}

export async function updateVendorProfile(input: {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}) {
  if (input) {
    return axios.put(`vendors/update-profile`, input);
  }
}
