import { Box, Flex, Text, Button, useDisclosure, Modal, ModalOverlay, ModalHeader, ModalContent, ModalCloseButton, ModalBody, ModalFooter, Input, Stack, Center } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import handleErrorMessage from "@/utilities/get-error";
import { VendorState } from "@/features/auth/resources";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { TextInput } from "../Input";
import { useUpdateVendorProfile } from "@/features/dashboard/operations";

export function ProfileItem({ name, value, transform, isValid = false }: any) {
  return (
    <Box mt="1.5rem" mr="0.2rem">
      <Flex
        gap={["0.5rem", "1rem"]}
        fontSize={["13px", "15px"]}
        alignItems={"center"}
        justifyContent="space-between"
        fontWeight="500"
      >
        <Text>{name}</Text>
        <Text
          textTransform={transform ? transform : ""}
          mt={"0.2rem"}
          opacity={"0.7"}
          fontSize="14px"
        >
          {value}
        </Text>
      </Flex>
    </Box>
  );
}

export default function PersonalDetails() {
  const [vendor, setVendor] = useRecoilState(VendorState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {updateVendorProfile, updateVendorProfileResult} = useUpdateVendorProfile()

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
  useFormik({
    initialValues: {
      firstName: vendor.firstName,
      lastName: vendor.lastName,
      phoneNumber: vendor.phoneNumber,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Please provide your first name"),
      lastName: Yup.string().required("Please provide your last name"),
      phoneNumber: Yup.string().required("Please provide your phone number")
    }),
    onSubmit: async () => {
      try {
        const {firstName, lastName, phoneNumber } = values
        const data = await updateVendorProfile({ firstName, lastName, phoneNumber });
        toast('Changes made successfully', { type: 'success' });
        if (data) {
          setVendor(data.data);
        }
        onClose();
      } catch (error) {
        handleErrorMessage(error);
      }
    }
  });

  return (
    <div>
      <Box width="100%">
        <Text userSelect={"none"} opacity="0.6" width="200px" fontWeight={"700"}>
          PERSONAL DETAILS
        </Text>
        <Button size='xs' color="var(--keza-brown)" onClick={onOpen} >edit</Button>
      </Box>
      
      
      <Box w="100%" my={"1rem"} h="1px" opacity={"0.15"} bg="black"></Box>

      <Box>
        <ProfileItem
          transform={"capitalize"}
          name={"Full name"}
          value={`${vendor?.lastName.toLowerCase()} ${vendor?.firstName.toLowerCase()}`}
        />
        <ProfileItem name={"Email address"} value={vendor?.email} />
        <ProfileItem
          transform={"capitalize"}
          name={"Phone number"}
          value={vendor?.phoneNumber}
        />
      </Box>
      
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Text fontSize={"15"} opacity="0.5">
            Update your personal information
          </Text>

          <Box my={"4rem"}>
            <TextInput
              label="First Name"
              name="firstName"
              onChange={handleChange}
              value={values.firstName}
              isInvalid={!!errors.firstName}
              error={!!errors.firstName}
              onBlur={handleBlur}
              touched={touched.firstName}
              errorMessage={errors.firstName}
            />
            <br />
             <TextInput
              label="Last Name"
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
              isInvalid={!!errors.lastName}
              error={!!errors.lastName}
              onBlur={handleBlur}
              touched={touched.lastName}
              errorMessage={errors.lastName}
            />
            <br />
            <TextInput
              label="Phone Number"
              name="phoneNumber"
              onChange={handleChange}
              value={values.phoneNumber}
              isInvalid={!!errors.phoneNumber}
              error={!!errors.phoneNumber}
              onBlur={handleBlur}
              touched={touched.phoneNumber}
              errorMessage={errors.phoneNumber}
            />
          </Box>

          <Center>
            <Button
              colorScheme={"var(keza-brown)"}
              onClick={() => handleSubmit()}
              color={"white"}
              bg="var(--keza-brown)"
              isLoading={updateVendorProfileResult.isLoading}
            >
              Save changes
            </Button>
          </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
