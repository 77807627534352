import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPrevious,
  usePagination
} from "@ajna/pagination";
import {
  Box,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useGetVendorApplications } from "../operations";
import { ApplicationDetails } from "../types";
import getUserToken from "@/utilities/get-user-token";
import SectionHeader from "./SectionHeader";
import Progress from "@/components/Progress";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { getTag } from "@/utilities/get-status-tag";

const Applications = () => {
  const token = getUserToken();

  const [applicationList, setApplicationList] = useState({
    pagination: { page: 0, size: 10, totalDocs: 1, numberOfPages: 1 },
    content: [] as Partial<ApplicationDetails>[]
  });

  const { getVendorApplications, getVendorApplicationsResult } =
    useGetVendorApplications(data => setApplicationList(data));

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: applicationList.pagination.numberOfPages,
    initialState: { currentPage: 1 }
  });

  const loading = getVendorApplicationsResult.isLoading;

  useEffect(() => {
    if (token) {
      getVendorApplications({ page: currentPage });
    }

    // eslint-disable-next-line
  }, [token, currentPage]);

  return (
    <div className="dash-child">
      <SectionHeader />

      <Box>
        <Text fontSize={"24px"} fontWeight="600" fontFamily="BR Firma">
          Applications
        </Text>
      </Box>

      <Box my={"2rem"}>
        <Box
          mt={"2rem"}
          border="0.5px solid rgba(29, 29, 29, 0.5)"
          borderRadius="5px"
          w={"100%"}
        >
          <Flex
            alignItems={"center"}
            justifyContent="space-between"
            padding={"1rem 1.5rem"}
          >
            <Text fontSize={"14px"}>
              Showing {applicationList?.content?.length} of{" "}
              {applicationList?.pagination?.totalDocs} results
            </Text>
          </Flex>
          <TableContainer w={"100% !important"} overflowX="auto">
            <Table
              variant="simple"
              borderRadius={"10px"}
              fontSize={"18px"}
              size={"sm"}
            >
              <Thead bg={"#EDEDED"}>
                <Tr
                  fontFamily={"DM Sans"}
                  fontWeight="14px"
                  opacity={"0.7"}
                  fontSize="14px"
                >
                  <Td>User</Td>
                  <Td>Device name</Td>
                  <Td>RAM / ROM</Td>
                  <Td>Device condition</Td>
                  <Td>Status</Td>
                </Tr>
              </Thead>
              <Tbody fontSize="16px">
                {applicationList?.content?.map(application => {
                  return (
                    <Tr key={application._id} bg={"#FFF7F4"}>
                      <Td>
                        <Box>
                          <Text>
                            {application?.owner
                              ? `${application?.owner?.firstName} ${application?.owner?.lastName}`
                              : "-"}
                          </Text>
                          <Text my={"0.4rem"} fontSize="12px" opacity={"0.8"}>
                            {moment(String(application?.createdAt)).format(
                              "Do [of] MMMM, YYYY"
                            )}
                          </Text>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <Text>{application?.product?.name}</Text>
                          <Text my={"0.4rem"} fontSize="12px" opacity={"0.6"}>
                            {application?.phoneColor}
                          </Text>
                        </Box>
                      </Td>
                      <Td> {application?.phoneStorage}</Td>
                      <Td textTransform={"capitalize"}>
                        {application?.phonCondition?.toLowerCase()}
                      </Td>
                      <Td>{getTag(application?.status || "pending")}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {Boolean(applicationList.content?.length) && (
        <Center>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer
              gap={3}
              maxW={"80%"}
              mx="auto"
              display={"flex"}
              alignItems="center"
            >
              <PaginationPrevious className="go-left" w={"20%"}>
                <MdKeyboardArrowLeft
                  id="ch-ph-drop-down"
                  color={"var(--keza-brown)"}
                  size={"30px"}
                />
              </PaginationPrevious>
              <div className="pag-num">
                <span>{currentPage}</span> <span>/</span>{" "}
                <span>{pages.length}</span>
              </div>
              <PaginationNext className="go-left" w={"20%"}>
                <MdKeyboardArrowRight
                  id="ch-ph-drop-down"
                  color={"var(--keza-brown)"}
                  size={"30px"}
                />
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Center>
      )}
      <Progress showOverlay loading={loading} />
    </div>
  );
};

export default Applications;
