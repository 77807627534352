import { ProductRequestState } from "@/features/dashboard/resources/vendor";
import { RequestType } from "@/features/dashboard/types";
import { getStatus } from "@/utilities/get-status";
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Button,
  useDisclosure
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import PlaceOfferModal from "./PlaceOfferModal";

export const request = [1, 2, 3, 4, 5];
export default function DashboardTable() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const productRequests = useRecoilValue(ProductRequestState);
  return (
    <Box maxW={"100%"} overflowX="auto">
      <Text mb={"1rem"} fontWeight={"500"}>
        Incoming Product requests
      </Text>
      <TableContainer
        w={"100% !important"}
        border={"1px solid rgba(0,0,0,0.5)"}
      >
        <Table
          w={"100%"}
          bg="green"
          variant="simple"
          borderRadius={"10px"}
          size={"sm"}
        >
          <Thead bg={"#EDEDED"}>
            <Tr
              fontFamily={"DM Sans"}
              fontWeight="14px"
              opacity={"0.7"}
              fontSize="14px"
            >
              <Td>Device name</Td>
              <Td>RAM / ROM</Td>
              <Td>Device condition</Td>
              <Td>Action</Td>
            </Tr>
          </Thead>
          <Tbody>
            {productRequests?.content?.map(
              (request: RequestType, index: any) => {
                return (
                  <Tr key={index} bg={"#FFF7F4"}>
                    <Td>
                      <Box>
                        <Text>{request?.application?.productDetail?.name}</Text>
                        <Text my={"0.4rem"} fontSize="12px" opacity={"0.6"}>
                          Space Black
                        </Text>
                      </Box>
                    </Td>
                    <Td> {request?.application?.phoneStorage}</Td>
                    <Td textTransform={"capitalize"}>
                      {request?.application?.phonCondition?.toLowerCase()}
                    </Td>
                    <Td>
                      <Button
                        fontWeight="400"
                        variant="outline"
                        fontSize={{ base: "14px", md: "" }}
                        bg={"transparent"}
                        borderRadius="1000px"
                        borderColor={"black"}
                        _hover={{
                          bg: "transparent"
                        }}
                        onClick={() => {
                          if (!request.isAccepted) {
                            onOpen();
                          } else {
                            return;
                          }
                        }}
                        w={{ base: "6rem", md: "9rem" }}
                        h={{ base: "2rem", md: "" }}
                      >
                        {getStatus(request.bid, request.isAccepted)}
                      </Button>
                    </Td>
                    {request.isAccepted ? (
                      <></>
                    ) : (
                      <Td display={"none"}>
                        <PlaceOfferModal
                          isOpen={isOpen}
                          onClose={onClose}
                          requestId={request._id}
                          application={request.application}
                          requestDate={request.createdAt}
                        />
                      </Td>
                    )}
                  </Tr>
                );
              }
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
