import { Product } from "@/interfaces/product.interface";
import {
  Box,
  Button,
  Heading,
  Image,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";

const InventoryItem = ({
  product,
  onClick,
  onSecondaryClick,
  isAdded = false
}: {
  product: Product;
  onClick?: () => any;
  onSecondaryClick?: () => any;
  isAdded?: boolean;
}) => {
  return (
    <Box
      w={"80%"}
      p={"14px"}
      background={"FFFFFF"}
      border={"0.346829px solid rgba(0, 0, 0, 0.5)"}
      borderRadius={"15px"}
    >
      <VStack>
        <Box transform={"scale(0.7)"}>
          <Image w={"full"} src={product?.image?.[0]} h={"auto"} />
        </Box>
        <Heading
          fontFamily={"DM Sans"}
          fontStyle={"normal"}
          fontWeight={"500"}
          fontSize={"16px"}
          lineHeight={"21px"}
          color={"#1D1D1D"}
          mb={"21px"}
        >
          {product.name}
        </Heading>
        <Text
          fontFamily={"DM Sans"}
          fontStyle={"normal"}
          fontWeight={"500"}
          fontSize={"14px"}
          lineHeight={"18px"}
          color={"#1D1D1D"}
          opacity={"0.6"}
          textAlign={"center"}
          mb={"8px"}
        >
          {product.pricing.map(price => price.storageRAM).join("/")}
        </Text>
        <Text
          fontFamily={"DM Sans"}
          fontStyle={"normal"}
          fontWeight={"500"}
          fontSize={"14px"}
          lineHeight={"18px"}
          color={"#1D1D1D"}
          opacity={"0.6"}
          textAlign={"center"}
          mb={"10px"}
        >
          {product.colors.join(", ")}
        </Text>
        <Stack direction={["column", "row"]}>
          <Button
            height={"unset"}
            w={"fit-content"}
            border={"0.5px solid #AA4428"}
            borderRadius={"5px"}
            padding={"8px 24px"}
            fontFamily={"DM Sans"}
            fontStyle={"normal"}
            fontWeight={"500"}
            fontSize={"14px"}
            lineHeight={"18px"}
            color={"#AA4428"}
            background={"white"}
            onClick={onClick}
          >
            {isAdded ? "Update" : "Add to Shelf"}
          </Button>
          {isAdded && (
            <Button
              height={"unset"}
              w={"fit-content"}
              border={"0.5px solid #AA4428"}
              borderRadius={"5px"}
              padding={"8px 24px"}
              fontFamily={"DM Sans"}
              fontStyle={"normal"}
              fontWeight={"500"}
              fontSize={"14px"}
              lineHeight={"18px"}
              color={"#AA4428"}
              background={"white"}
              onClick={onSecondaryClick}
            >
              {"Remove"}
            </Button>
          )}
        </Stack>
        {/* <Button
          height={"unset"}
          w={"fit-content"}
          background={
            isAdded ? "rgba(35, 169, 73, 0.1)" : "rgba(233, 59, 20, 0.1)"
          }
          border={`0.505px solid ${isAdded ? "#23A949" : "#E93B14"}`}
          borderRadius={"16.8333px"}
          padding={"5px 25px"}
          fontFamily={"DM Sans"}
          fontStyle={"normal"}
          fontWeight={"500"}
          fontSize={"14px"}
          lineHeight={"18px"}
          color={isAdded ? "#23A949" : "#E93B14"}
        >
          In stock
        </Button> */}
      </VStack>
    </Box>
  );
};

export default InventoryItem;
