import Layout from "../compoents/Layout";
import ForgotPassword from "../compoents/ForgotPassword";

export default function ForgotPasswordRoute() {
  return (
    <Layout>
      <ForgotPassword />
    </Layout>
  );
}
