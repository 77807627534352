// import { PAYSTACK_SECRET } from "@/config";
import axios from "axios";
// import { ResolveAccount } from "../types/paystack";

const api = axios.create({ baseURL: `https://api.paystack.co` });
// const token = PAYSTACK_SECRET;

export const getAllBanks = () => {
  return api.get(`/bank`);
};

// export const resolveAccount = (data: ResolveAccount) => {
//   return api.get(
//     `/bank/resolve?account_number=${data.account_number}&bank_code=${data.bank_code}`,
//     { headers: { Authorization: `Bearer ${token}` } }
//   );
// };
