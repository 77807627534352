import { Tag } from "antd";

export const getTag = (status: string) => {
  switch (status.toLowerCase()) {
    case "pending": {
      return <Tag color="warning">{status.toUpperCase()}</Tag>;
    }
    case "success":
    case "approved":
    case "completed":
    case "passed":
    case "active":
    case "paid":
    case "credit":
    case "confirmed":
      return <Tag color="success">{status.toUpperCase()}</Tag>;
    case "failed":
    case "debit":
    case "rejected":
    case "cancelled":
    case "declined":
      return <Tag color="error">{status.toUpperCase()}</Tag>;
    default:
      return <Tag>{status.toUpperCase()}</Tag>;
  }
};
