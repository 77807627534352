import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Button,
  Center,
  Box,
  Divider
} from "@chakra-ui/react";
import { TextInput } from "../Input";
import { formatValue } from "@/utilities/format-comma-input";
import { usePlaceOffer } from "@/features/dashboard/operations";
import { toast } from "react-toastify";
import { Application } from "@/features/dashboard/types";
import moment from "moment";

export function RequestItem({ name, value }: { name: string; value: string }) {
  return (
    <Flex fontSize={["13px", "15px"]} gap="3rem">
      <Box>
        <Text opacity={"0.5"} fontWeight="16px">
          {name}
        </Text>
      </Box>

      <Box>
        <Text textTransform={"capitalize"} fontWeight={"500"}>
          {value}
        </Text>
      </Box>
    </Flex>
  );
}

type placeOfferModalType = {
  isOpen: boolean;
  onClose: () => void;
  requestId: string;
  application: Application;
  requestDate: string;
};
export default function PlaceOfferModal({
  isOpen,
  onClose,
  requestId,
  application,
  requestDate
}: placeOfferModalType) {
  const [amount, setAmount] = useState("");
  const { placeOffer, placeOfferResult } = usePlaceOffer();
  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    setAmount(formatValue(inputValue));
  };
  const handleInputBlur = (event: any) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^\d]/g, "");
    setAmount(numericValue);
  };
  async function submitRequest() {
    const res: any = await placeOffer({ bid: Number(amount), id: requestId });
    if (res.bid > 0) {
      toast("Operation successfull", { type: "success" });
    }
    onClose();
  }
  return (
    <div>
      <>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent w={["95%", ""]}>
            <ModalCloseButton />
            <ModalBody p={"3rem 2rem"}>
              <Text color={"var(--keza-brown)"} fontWeight={"700"}>
                Place offer{" "}
              </Text>
              <Text fontSize={["14px", ""]} opacity="0.5">
                Enter pricing details for this device
              </Text>

              <Center
                fontWeight={"600"}
                fontSize="16px"
                mt="2rem"
                w={"10rem"}
                bg={"#FFF2DF"}
                p="0.2rem"
              >
                Request details
              </Center>

              {/* ===================================================================================== */}
              <Flex direction={"column"} gap={"1rem"} mt={"2rem"}>
                <RequestItem
                  name={"Request date:"}
                  value={moment(String(requestDate)).format(
                    "Do [of] MMMM, YYYY"
                  )}
                />
                <RequestItem
                  name={"Request time:"}
                  value={moment(requestDate).format("h:mm A")}
                />
                <RequestItem
                  name={"Device name:"}
                  value={application?.productDetail?.name}
                />
                <RequestItem
                  name={"RAM / ROM:"}
                  value={application?.phoneStorage}
                />
                <RequestItem
                  name={"Device condition:"}
                  value={application?.phonCondition?.toLowerCase()}
                />
                <Flex my="0.5rem" fontSize={["13px", "15px"]} gap="3rem">
                  <Text opacity={"0.5"}>Color:</Text>
                  <Button
                    fontWeight="400"
                    fontSize={{ base: "12px", md: "" }}
                    h={"2rem"}
                    variant="outline"
                    bg={"transparent"}
                    borderRadius="1000px"
                    borderColor={"black"}
                    _hover={{
                      bg: "transparent"
                    }}
                    w={{ base: "6rem", md: "9rem" }}
                  >
                    {application.phoneColor}
                  </Button>
                </Flex>
                {/* ==================================================== */}
                <Divider />
                <Center
                  fontWeight={"600"}
                  fontSize="16px"
                  mt="2rem"
                  w={"8rem"}
                  bg={"#FFF2DF"}
                  p="0.2rem"
                >
                  Pricing
                </Center>
                <TextInput
                  label="Amount ₦"
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  name="amount"
                  value={formatValue(amount)}
                />
                <Center fontSize={"16px"} my={"1rem"} gap="1rem">
                  <Button
                    color={"white"}
                    bg="var(--keza-brown)"
                    _hover={{
                      bg: "var(--keza-brown)"
                    }}
                    onClick={submitRequest}
                    isLoading={placeOfferResult.isLoading}
                  >
                    Submit offer
                  </Button>
                </Center>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </div>
  );
}
