import { VendorState } from "@/features/auth/resources";
import { Box, Flex, Stack, Text, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import AccountModal from "../AccountModal/AccountModal";

export default function AccountCard() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [vendor] = useRecoilState(VendorState);
  return (
    <Flex
      w={"100%"}
      flexDirection={"column"}
      borderRadius="10px"
      border="1px dashed #1D1D1D"
      p={"1rem"}
    >
      <>
        <Flex mb={"1rem"} justifyContent={"space-between"}>
          <Text fontWeight={"600"}>Account</Text>
          <Text
            fontSize={"14px"}
            textDecoration="underline"
            color={"var(--keza-brown)"}
            cursor="pointer"
            onClick={onOpen}
          >
            {vendor?.accountNumber ? "Edit" : "Add account"}
          </Text>
        </Flex>

        <Flex fontSize={{ base: "14px", md: "16px" }} gap={"3.5rem"}>
          <Stack spacing={'2'}>
            <Text opacity={"0.6"}>Account name</Text>
            <Text opacity={"0.6"}>Bank</Text>
            <Text opacity={"0.6"}>Account number</Text>
          </Stack>

          <Stack spacing={'2'}>
            <Text>
              {vendor?.accountNumber ? `${vendor?.accountName}` : `---`}
            </Text>
            <Text>{vendor?.accountNumber ? `${vendor?.bankName}` : "---"}</Text>
            <Text>
              {vendor?.accountNumber ? `${vendor?.accountNumber}` : "---"}
            </Text>
          </Stack>
        </Flex>
      </>

      <AccountModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
}
