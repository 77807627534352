import jwt_decode from "jwt-decode";

export default function getUserToken() {
  const isTokenAvailable = localStorage.getItem("VendorTokenState");
  if (isTokenAvailable) {
    const token = JSON.parse(isTokenAvailable)?.VendorTokenState;
    return token;
  }
  return "";
}

export function isExpired() {
  let token = getUserToken();
  if (!token || token === "undefined") {
    return true;
  }
  let decodedToken: any = jwt_decode(token);
  let currentDate = new Date();
  // JWT exp is in seconds
  if (decodedToken?.exp * 1000 < currentDate.getTime()) {
    return true;
  } else {
    return false;
  }
}
