import React from "react";
import Layout from "../compoents/Layout";
import Login from "../compoents/Login";

export default function LoginRoute() {
  return (
    <Layout>
      <Login />
    </Layout>
  );
}
