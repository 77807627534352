import { Box, Flex, Image, Text } from "@chakra-ui/react";
import logo from "@/assets/auth/keza-logo.png";
import { HiMenuAlt3 } from "react-icons/hi";
import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import MobileMenu from "./MobileMenu";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useRecoilState } from "recoil";
import { VendorState } from "@/features/auth/resources";

export default function Header() {
  const navigate = useNavigate();
  const [vendor] = useRecoilState(VendorState);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <Box
        bg={{ base: "white", md: "var(--keza-dashboard)" }}
        px={4}
        boxShadow={["0px 2px 4px rgba(24, 24, 24, 0.05)", "", "none"]}
        position="fixed"
        w="100%"
        top={"0"}
        zIndex={"4"}
        pt="1rem"
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <Box
            maxW={["6rem", "8rem"]}
            onClick={() => navigate("/")}
            cursor="pointer"
          >
            <img src={logo} alt="logo" />
          </Box>

          <Flex
            alignItems={"center"}
            display={{ base: "none", md: "flex" }}
            gap="1rem"
          >
            <Text fontWeight={"600"} textTransform="capitalize">
              {vendor?.business?.name?.toLowerCase()}
            </Text>
            <Box>
              <Image
                w={"3rem"}
                h="3rem"
                borderRadius={"50%"}
                src={vendor.business.logo}
                alt="avatar"
              />
            </Box>
          </Flex>

          <Box
            onClick={() => setShowMenu(!showMenu)}
            display={{ base: "block", md: "none" }}
          >
            {showMenu ? (
              <RxCross1 size={"1.5rem"} />
            ) : (
              <HiMenuAlt3 size={"1.5rem"} />
            )}
          </Box>
        </Flex>
      </Box>

      <AnimatePresence>
        {showMenu && (
          <motion.div
            key={"header"}
            initial={{ x: 900 }}
            animate={{ x: 0 }}
            exit={{ x: 900 }}
            className="mob-head-wrapp"
          >
            <MobileMenu setShowMenu={setShowMenu} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
