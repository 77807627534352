import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import SectionHeader from "./SectionHeader";
import InventoryItem from "@/components/InventoryComponents/InventoryItem";
import { useRecoilState } from "recoil";
import { productListState, vendorPricingList } from "../resources/product";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useGetProducts,
  useGetVendorPricing,
  useRemoveVendorPricing
} from "../operations";
import { VendorState } from "@/features/auth/resources";
import Progress from "@/components/Progress";
import AddPricingModal from "@/components/InventoryComponents/AddPricingModal";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPrevious,
  usePagination
} from "@ajna/pagination";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Product } from "@/interfaces/product.interface";
import handleErrorMessage from "@/utilities/get-error";
import Papa from "papaparse";
import BulkUploadModal from "@/components/InventoryComponents/BulkUploadModal";
import { toast } from "react-toastify";

const Inventory = () => {
  const bulkUploadDisclosure = useDisclosure();
  const [vendor] = useRecoilState(VendorState);
  const [result, setResult] = useState<{
    data: Papa.ParseResult<unknown> | null;
    file: File | null;
  }>({ data: null, file: null });
  const [productList, setProductList] = useRecoilState(productListState);
  const [openModal, setOpenModal] = useState({ _id: "", isOpen: false });
  const [success, setSuccess] = useState(false);
  const [addedProduct, setAddedProduct] = useState<Product | null>(null);
  const [tabs, setTabs] = useState([
    { name: "Products", isActive: true },
    { name: "My Shelf", isActive: false }
  ]);
  const [search, setSearch] = useState("");
  const [priceList, setPriceList] = useRecoilState(vendorPricingList);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const pagination = useMemo(() => {
    return {
      pagesCount: tabs[1].isActive
        ? priceList.pagination.numberOfPages
        : productList.numberOfPages,
      initialState: { currentPage: 1 }
    };
  }, [tabs, priceList, productList]);

  const { currentPage, setCurrentPage, pagesCount, pages } =
    usePagination(pagination);

  const getColor = useCallback((val: boolean) => {
    if (val) {
      return "var(--keza-brown)";
    }
    return "";
  }, []);

  const { getProducts, getProductsResult } = useGetProducts(data =>
    setProductList(data)
  );

  const { removeVendorPricing, removeVendorPricingResult } =
    useRemoveVendorPricing(data => {
      if (data) {
        setPriceList({
          content: priceList.content.filter(price => price._id !== data._id),
          pagination: {
            ...priceList.pagination,
            totalDocs: priceList.pagination.totalDocs - 1,
            numberOfPages: Math.ceil(
              (priceList.pagination.totalDocs - 1) / priceList.pagination.size
            )
          }
        });
      }
    });

  const { getVendorPricing, getVendorPricingResult } = useGetVendorPricing(
    data => setPriceList(data)
  );

  const handleClick = (_id: string) => {
    setOpenModal({ _id, isOpen: true });
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];

    if (file) {
      Papa.parse(file as any, {
        header: true,
        skipEmptyLines: true,
        complete: data => {
          setResult({ data, file });
        }
      });
    }
  };

  const handleRemovePricing = async (id = "") => {
    try {
      if (id) {
        await removeVendorPricing(id);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const selectedPricing = useMemo(() => {
    if (priceList.content.length) {
      return (
        priceList.content.find(price => price.product._id === openModal._id) ||
        null
      );
    } else {
      return null;
    }
  }, [openModal._id, priceList.content]);

  const loading =
    getProductsResult.isLoading ||
    getVendorPricingResult.isLoading ||
    removeVendorPricingResult.isLoading;

  useEffect(() => {
    if (tabs.length) {
      if (vendor.username && tabs[1].isActive) {
        getVendorPricing({ username: vendor.username, page: currentPage });
      } else if (tabs[0].isActive) {
        getProducts({
          page: currentPage,
          search: tabs[0].isActive && !!search ? search : undefined
        });
      }
    }

    if (success) {
      setSuccess(false);
    }
    // eslint-disable-next-line
  }, [vendor.username, currentPage, success, search, tabs]);

  useEffect(() => {
    if (result.data) {
      bulkUploadDisclosure.onOpen();
    }
  }, [result.data]);

  return (
    <div className="dash-child">
      <SectionHeader />

      <BulkUploadModal
        file={result.file}
        result={result.data}
        isOpen={bulkUploadDisclosure.isOpen}
        onClose={bulkUploadDisclosure.onClose}
        onSuccess={() => {
          bulkUploadDisclosure.onClose();
          toast("Performed a Successful bulk upload operation", {
            type: "success"
          });
          setResult({ data: null, file: null });
          setTabs(
            tabs.map((initialTab, index) =>
              index === 1
                ? { ...initialTab, isActive: true }
                : { ...initialTab, isActive: false }
            )
          );
        }}
      />

      <AddPricingModal
        setSuccess={setSuccess}
        isOpen={openModal.isOpen}
        addedProduct={addedProduct}
        vendorPricing={selectedPricing}
        onClose={() => {
          setOpenModal({ _id: "", isOpen: false });
          if (addedProduct) {
            setAddedProduct(null);
          }
        }}
      />

      <Box>
        <Text fontSize={"24px"} fontWeight="600" fontFamily="BR Firma">
          Inventory
        </Text>
      </Box>

      <Box my={"2rem"}>
        <Flex
          alignItems={"center"}
          gap={{ base: "1rem", md: "2rem" }}
          fontSize={{ base: "14px", md: "" }}
        >
          {tabs.map(tab => (
            <Button
              key={tab.name}
              variant={"light"}
              size="sm"
              opacity={"0.7"}
              w={{ base: "6rem", md: "9rem" }}
              h={{ base: "2rem", md: "" }}
              color={getColor(tab.isActive)}
              borderColor={getColor(tab.isActive)}
              fontSize={{ base: "12px", md: "" }}
              onClick={() =>
                setTabs(
                  tabs.map(initialTab =>
                    initialTab.name === tab.name
                      ? { ...tab, isActive: true }
                      : { ...initialTab, isActive: false }
                  )
                )
              }
            >
              {tab.name}
              <span style={{ marginLeft: "1rem", color: "#E93B14" }}>
                {tab.name === "Products"
                  ? productList?.totalCount - priceList?.content?.length
                  : priceList?.content?.length}
              </span>
            </Button>
          ))}
        </Flex>
        <Box
          mt={"2rem"}
          border="0.5px solid rgba(29, 29, 29, 0.5)"
          borderRadius="5px"
          w={"100%"}
        >
          <Flex
            alignItems={"center"}
            justifyContent="space-between"
            padding={"1rem 1.5rem"}
            gap={["1em", "0"]}
            direction={{ base: "column", md: "row" }}
          >
            {tabs[0].isActive && (
              <Button
                border={"0.5px solid #AA4428"}
                color={"#AA4428"}
                variant={"outline"}
                onClick={() => inputRef.current?.click()}
              >
                Bulk Upload
                <input
                  type={"file"}
                  hidden
                  ref={inputRef as any}
                  accept=".csv"
                  onChange={handleFileUpload}
                />
              </Button>
            )}
            <Text fontSize={"14px"}>
              Showing{" "}
              {tabs[1].isActive
                ? priceList?.content?.length
                : productList?.list?.length}{" "}
              of{" "}
              {tabs[1].isActive
                ? priceList?.pagination?.totalDocs
                : productList?.totalCount}{" "}
              results
            </Text>
            {tabs[0].isActive && (
              <Center w={["90%", "50%"]}>
                <Input
                  w={"full"}
                  value={search}
                  placeholder={"Search for your device"}
                  _focus={{ borderColor: "var(--keza-brown)" }}
                  onChange={event => setSearch(event.target.value)}
                />
              </Center>
            )}
          </Flex>
          <Box
            display={{ base: "flex", xl: "grid" }}
            gridTemplateColumns={"repeat(4, minmax(0, 1fr))"}
            m={{ base: "8px", xl: "0px 0 24px 24px" }}
            alignItems={{ base: "center", xl: "unset" }}
            flexDir={"column"}
            gap={"24px"}
          >
            {tabs[1].isActive
              ? priceList.content.map(price => (
                  <InventoryItem
                    isAdded
                    key={price._id}
                    product={price.product}
                    onClick={() => handleClick(price.product._id)}
                    onSecondaryClick={() => {
                      const confirmed = window.confirm(
                        "Are you sure you want to do this? If you do this, you will lose your pricing details."
                      );
                      if (confirmed) {
                        handleRemovePricing(price._id);
                      }
                    }}
                  />
                ))
              : productList?.list?.map(product => (
                  <InventoryItem
                    key={product._id}
                    product={product}
                    onClick={() => {
                      setAddedProduct(
                        productList?.list?.find(
                          initialProduct => initialProduct._id === product._id
                        ) || null
                      );

                      setOpenModal({ _id: product._id, isOpen: true });
                    }}
                  />
                ))}
          </Box>
        </Box>
      </Box>
      {Boolean(priceList.content?.length || productList?.list?.length) && (
        <Center>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer
              gap={3}
              maxW={"80%"}
              mx="auto"
              display={"flex"}
              alignItems="center"
            >
              <PaginationPrevious className="go-left" w={"20%"}>
                <MdKeyboardArrowLeft
                  id="ch-ph-drop-down"
                  color={"var(--keza-brown)"}
                  size={"30px"}
                />
              </PaginationPrevious>
              <div className="pag-num">
                <span>{currentPage}</span> <span>/</span>{" "}
                <span>{pages?.length}</span>
              </div>
              <PaginationNext className="go-left" w={"20%"}>
                <MdKeyboardArrowRight
                  id="ch-ph-drop-down"
                  color={"var(--keza-brown)"}
                  size={"30px"}
                />
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Center>
      )}
      <Progress showOverlay loading={loading} />
    </div>
  );
};

export default Inventory;
