import { VendorState } from "@/features/auth/resources";
import { useUpdateVendor } from "@/features/dashboard/operations";
import {
  VendorDeviceCondition,
  VendorDeviceModel
} from "@/features/dashboard/types";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Text
} from "@chakra-ui/react";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

const VendorSettings = () => {
  const [vendor, setVendor] = useRecoilState(VendorState);

  const { updateVendor, updateVendorResult } = useUpdateVendor();

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      models: vendor?.settings?.deviceModels || [],
      conditions: vendor?.settings?.deviceConditions || []
    },
    enableReinitialize: true,
    onSubmit: async values => {
      const data = await updateVendor({
        deviceConditions: values.conditions,
        deviceModels: values.models
      });

      if (data) {
        setVendor(data.data);
        toast((data as any).message, { type: "success" });
      }
    }
  });

  const getConditionName = useCallback((condition: VendorDeviceCondition) => {
    if (condition === VendorDeviceCondition.NEW) {
      return "New";
    } else {
      return "Pre Owned";
    }
  }, []);

  return (
    <Box mt={"2rem"}>
      <Text
        userSelect={"none"}
        opacity="0.6"
        fontWeight={"700"}
        fontSize="16px"
      >
        SETTINGS
      </Text>
      <Box w="100%" my={"1rem"} h="1px" opacity={"0.15"} bg="black"></Box>

      <FormControl mb={"5"}>
        <FormLabel>Preferred Device Conditions</FormLabel>
        <CheckboxGroup
          colorScheme="teal"
          value={values.conditions}
          onChange={values => setFieldValue("conditions", values)}
        >
          <Stack direction={["column", "row"]} spacing={[1, 5]}>
            {Object.values(VendorDeviceCondition).map((condition,index) => (
              <Checkbox key={index} value={condition}>
                {getConditionName(condition)}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Preferred Device Brands</FormLabel>
        <CheckboxGroup
          colorScheme="teal"
          value={values.models}
          onChange={values => setFieldValue("models", values)}
        >
          <Stack
            direction={["column", "row"]}
            flexWrap={"wrap"}
            spacing={[1, 3]}
          >
            {Object.values(VendorDeviceModel).map(condition => (
              <Checkbox value={condition}>{condition}</Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </FormControl>

      <Flex alignItems={"center"} justifyContent={"center"} mt={"1rem"}>
        <Button
          isLoading={updateVendorResult.isLoading}
          id="auth-btn"
          onClick={() => handleSubmit()}
          className="form-btn brwn-btn"
        >
          Update
        </Button>
      </Flex>
    </Box>
  );
};

export default VendorSettings;
