import { CircularProgress, Flex } from "@chakra-ui/react";

const Progress = ({ loading = false, lazy = false, showOverlay = false }) => {
  return (
    <Flex
      className={`modal-backdrop${loading ? " show" : ""}${
        lazy ? " lazy" : ""
      }${showOverlay ? " show-overlay" : ""}`}
      w="100vw"
      h="100vh"
      justifyContent={"center"}
      alignItems="center"
    >
      <CircularProgress
        mx={"90vh"}
        w="100%"
        color={"var(--keza-brown)"}
        trackColor={"white"}
        isIndeterminate
      />
    </Flex>
  );
};

export default Progress;
