import { Box, Text } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { ProfileItem } from "./PersonalDetails";
import { VendorState } from "@/features/auth/resources";

export default function BusinessInfo() {
  const vendor = useRecoilValue(VendorState);

  return (
    <Box mt={"2rem"}>
      <Text
        userSelect={"none"}
        opacity="0.6"
        fontWeight={"700"}
        fontSize="16px"
      >
        BUSINESS INFORMATION
      </Text>
      <Box w="100%" my={"1rem"} h="1px" opacity={"0.15"} bg="black"></Box>

      <Box>
        <ProfileItem
          transform={"capitalize"}
          name={"Business name"}
          value={vendor?.business?.name}
        />
        <ProfileItem
          transform={"capitalize"}
          name={"Business address"}
          value={vendor?.business?.address}
        />
      </Box>
    </Box>
  );
}
