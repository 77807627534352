import { useMutation } from "react-query";
import {
  addVendorPricing,
  getProducts,
  getRequests,
  getVendor,
  getVendorApplications,
  getVendorPricing,
  placeOffer,
  removeVendorPricing,
  updateVendor,
  updateVendorUsername,
  uploadBulkPricing,
  uploadDocument,
  uploadImage,
  uploadLogo,
  updateVendorProfile
} from "../api";
import { useRecoilState } from "recoil";
import { VendorState } from "@/features/auth/resources";

export function usePlaceOffer() {
  const { mutateAsync, ...placeOfferResult } = useMutation(placeOffer);
  return {
    placeOffer: mutateAsync,
    placeOfferResult
  };
}
export function useGetRequest() {
  const { mutateAsync, ...getRequestsResult } = useMutation(getRequests);

  return { getRequests: mutateAsync, getRequestsResult };
}

export function useUpdateVendor() {
  const { mutateAsync, ...updateVendorResult } = useMutation(updateVendor);

  return { updateVendor: mutateAsync, updateVendorResult };
}

export function useUpdateVendorUsername() {
  const { mutateAsync, ...updateVendorUsernameResult } =
    useMutation(updateVendorUsername);

  return { updateVendorUsername: mutateAsync, updateVendorUsernameResult };
}

export function useUploadDocument() {
  const { mutateAsync, ...uploadDocumentResult } = useMutation(uploadDocument);

  return { uploadDocument: mutateAsync, uploadDocumentResult };
}

export function useUploadImage() {
  const { mutateAsync, ...uploadImageResult } = useMutation(uploadImage);

  return { uploadImage: mutateAsync, uploadImageResult };
}

export function useUploadLogo() {
  const { mutateAsync, ...uploadLogoResult } = useMutation(uploadLogo);

  return { uploadLogo: mutateAsync, uploadLogoResult };
}

export function useGetVendor() {
  const [, setVendor] = useRecoilState(VendorState);

  const { mutateAsync, ...getVendorResult } = useMutation(getVendor, {
    onSuccess: data => {
      if (data.data) {
        setVendor(data.data);
      }
    }
  });

  return { getVendor: mutateAsync, getVendorResult };
}

export function useGetProducts(onCompleted?: (data: any) => void) {
  const { mutateAsync, ...getProductsResult } = useMutation(getProducts, {
    onSuccess: data => {
      if (data) {
        onCompleted && onCompleted(data);
      }
    }
  });

  return { getProducts: mutateAsync, getProductsResult };
}

export function useGetVendorPricing(onCompleted?: (data: any) => void) {
  const { mutateAsync, ...getVendorPricingResult } = useMutation(
    getVendorPricing,
    {
      onSuccess: data => {
        if (data) {
          onCompleted && onCompleted(data);
        }
      }
    }
  );

  return { getVendorPricing: mutateAsync, getVendorPricingResult };
}

export function useGetVendorApplications(onCompleted?: (data: any) => void) {
  const { mutateAsync, ...getVendorApplicationsResult } = useMutation(
    getVendorApplications,
    {
      onSuccess: data => {
        if (data) {
          onCompleted && onCompleted(data);
        }
      }
    }
  );

  return { getVendorApplications: mutateAsync, getVendorApplicationsResult };
}

export function useAddVendorPricing(onCompleted?: (data: any) => void) {
  const { mutateAsync, ...addVendorPricingResult } = useMutation(
    addVendorPricing,
    {
      onSuccess: data => {
        if (data?.data) {
          onCompleted && onCompleted(data.data);
        }
      }
    }
  );

  return { addVendorPricing: mutateAsync, addVendorPricingResult };
}

export function useRemoveVendorPricing(onCompleted?: (data: any) => void) {
  const { mutateAsync, ...removeVendorPricingResult } = useMutation(
    removeVendorPricing,
    {
      onSuccess: data => {
        if (data?.data) {
          onCompleted && onCompleted(data.data);
        }
      }
    }
  );

  return { removeVendorPricing: mutateAsync, removeVendorPricingResult };
}

export function useUploadBulkPricing(onCompleted?: (data: any) => void) {
  const { mutateAsync, ...uploadBulkPricingResult } = useMutation(
    uploadBulkPricing,
    {
      onSuccess: data => {
        if (data?.data) {
          onCompleted && onCompleted(data.data);
        }
      }
    }
  );

  return { uploadBulkPricing: mutateAsync, uploadBulkPricingResult };
}


export function useUpdateVendorProfile() {
  const { mutateAsync, ...updateVendorProfileResult } =
    useMutation(updateVendorProfile);

  return { updateVendorProfile: mutateAsync, updateVendorProfileResult };
}