import { PasswordInput, TextInput } from "@/components/Input";
import {
  Box,
  Button,
  Center,
  FormControl,
  Grid,
  GridItem,
  Text
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useRecoilState } from "recoil";
import { VendorSignUpState } from "../resources";
import { VendorRegisterAtom } from "../types";
import * as Yup from "yup";

type PersonalDetailsProps = {
  prop: {
    step: number;
    setStep: any;
    formCompleted: boolean;
  };
};
export default function PersonalDetails({ prop }: PersonalDetailsProps) {
  const [
    {
      personal: { ...initialValues },
      business
    },
    setVendorSignupState
  ] = useRecoilState(VendorSignUpState);
  const { setStep } = prop;

  const { values, handleChange, submitForm, errors, touched } = useFormik<
    VendorRegisterAtom["personal"]
  >({
    initialValues,
    onSubmit: async () => {
      setVendorSignupState({
        business,
        personal: { ...values }
      });

      setStep(2);
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required().min(3),
      lastName: Yup.string().required().min(3),
      phoneNumber: Yup.string().required(),
      email: Yup.string().email().required(),
      password: Yup.string().required(),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required()
    })
  });

  return (
    <Box my={"2rem"}>
      <Text
        fontSize={{ base: "16px", md: "18px" }}
        mb={"0.5rem"}
        opacity={"0.5"}
      >
        Personal details
      </Text>
      <Text fontSize={{ base: "14px", md: "16px" }} color="var(--keza-brown)">
        Provide your profile information{" "}
      </Text>

      <FormControl my={"2rem"}>
        <Grid
          rowGap={"2rem"}
          columnGap="2rem"
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={6}
        >
          <GridItem>
            <TextInput
              name="firstName"
              onChange={handleChange}
              label={"First name"}
              value={values.firstName}
              error={errors.firstName}
              touched={touched.firstName}
              errorMessage={"Please input your first name"}
            />
          </GridItem>
          <GridItem>
            <TextInput
              name="lastName"
              onChange={handleChange}
              label={"Last name"}
              value={values.lastName}
              error={errors.lastName}
              touched={touched.lastName}
              errorMessage={"Please input your last name"}
            />
          </GridItem>
          <GridItem>
            <TextInput
              name="phoneNumber"
              onChange={handleChange}
              label={"Phone number"}
              error={errors.phoneNumber}
              touched={touched.phoneNumber}
              placeHolder="WhatsApp number"
              value={values.phoneNumber}
              errorMessage={"Please input your whatsApp number"}
            />
          </GridItem>
          <GridItem>
            <TextInput
              name="email"
              onChange={handleChange}
              label={"Email address"}
              error={errors.email}
              touched={touched.email}
              value={values.email}
              errorMessage={"Please input a valid email"}
            />
          </GridItem>
          <GridItem>
            <PasswordInput
              name="password"
              onChange={handleChange}
              label={"Password"}
              value={values.password}
              error={errors.password}
              touched={touched.password}
              errorMessage={"Please input a valid password"}
            />
          </GridItem>
          <GridItem>
            <PasswordInput
              name="confirmPassword"
              onChange={handleChange}
              label={"Confirm password"}
              error={errors.confirmPassword}
              touched={touched.confirmPassword}
              errorMessage={"You passwords must match"}
              value={values.confirmPassword}
            />
          </GridItem>
        </Grid>
      </FormControl>

      <Center>
        <Button
          color="white"
          bg={"var(--keza-brown)"}
          size={"lg"}
          _hover={{
            bg: "rgba(29, 29, 29, 0.1);"
          }}
          w={{ base: "15rem" }}
          onClick={submitForm}
        >
          Next
        </Button>
      </Center>
    </Box>
  );
}
