import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import sideImage from "@/assets/auth/Frame 648signup.png";
import "./index.css";
import logo from "@/assets/auth/keza-logo.png";

type LayoutProps = {
  children: React.ReactNode;
};

export default function Layout({ children }: LayoutProps) {
  const [step] = useState(2);

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.`
    if (React.isValidElement(child)) {
      return React.cloneElement(child, step);
    }
    return child;
  });
  return (
    <Flex
      justifyContent="space-between"
      pt={{ base: "3rem", md: "6rem" }}
      minH={"100vh"}
      gap={"3rem"}
      maxW="92%"
      mx={{ base: "auto", xl: "0px" }}
      ml={{ base: "none", xl: "auto" }}
    >
      <Box flex="1">
        <Box mb={"2rem"} maxW={"7rem"}>
          <img width={"100%"} src={logo} alt="logo" />
        </Box>
        <Box border="none">{childrenWithProps}</Box>
      </Box>

      <Box display={{ base: "none", xl: "block" }}>
        <Box maxW={"40vw"}>
          <img height={"100%"} src={sideImage} alt="img" />
        </Box>
      </Box>
    </Flex>
  );
}
