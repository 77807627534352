import Layout from "../compoents/Layout";
import ResetPassword from "../compoents/ResetPassword";

export default function ResetPasswordRoute() {
  return (
    <Layout>
      <ResetPassword />
    </Layout>
  );
}
