import { Product, VendorPricing } from "@/interfaces/product.interface";
import { atom } from "recoil";

export const productListState = atom({
  key: "productListState",
  default: {
    totalCount: 1,
    numberOfPages: 1,
    list: [] as Product[]
  }
});

export const vendorPricingList = atom({
  key: "vendorPricingList",
  default: {
    pagination: { page: 0, size: 10, totalDocs: 1, numberOfPages: 1 },
    content: [] as VendorPricing[]
  }
});
