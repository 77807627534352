import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import Button from "./components/button";
export default function ThemeProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const theme = extendTheme({
    components: {
      Button
    }
  });
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}
