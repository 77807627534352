import { useUploadBulkPricing } from "@/features/dashboard/operations";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Text
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import Papa from "papaparse";
import { useMemo } from "react";
import { DEVICE_CONDITIONS } from "@/constants";
import { omit } from 'lodash';

const BulkUploadModal = ({
  isOpen,
  onClose,
  result = null,
  file = null,
  onSuccess
}: {
  isOpen: boolean;
  result?: Papa.ParseResult<unknown> | null;
  file?: File | null;
  onClose: () => any;
  onSuccess: (result: AxiosResponse["data"]) => any;
}) => {
  const { uploadBulkPricing, uploadBulkPricingResult } = useUploadBulkPricing();
  const handleClose = () => {
    onClose();
  };

  const options = useMemo(
    () =>
      (result?.meta.fields || [])?.map(field => (
        <option
          key={field}
          value={field}
          style={{ textTransform: "capitalize" }}
        >
          {field.toLowerCase()}
        </option>
      )),
    [result]
  );

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name: "",
      price: "",
      storageROM: "",
      storageRAM: "",
      condition: ""
    },

    onSubmit: async values => {
      if (file && result) {
        const form = new FormData();
        form.append("csv", file, file.name);
        form.append("condition", values.condition);
        form.append("fields", JSON.stringify({...(omit(values, ['condition']))
      }));
        
        onSuccess(await uploadBulkPricing(form));
      }
    }
  });

  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent w={"full"}>
        <ModalCloseButton />
        <ModalBody p={"3rem 2rem"}>
          {result && file && (
            <>
              <Text color={"var(--keza-brown)"} fontWeight={"700"}>
                Bulk Upload for {file.name}
              </Text>
              <Stack m={"2rem 0"} direction={"column"} gap={"10px"}>
                <FormControl>
                  <FormLabel>
                    What field should be used for the device's ROM storage?
                  </FormLabel>
                  <Select
                    placeholder="Please select your desired field"
                    name="storageROM"
                    onChange={handleChange}
                    value={values.storageROM}
                    style={{ textTransform: "capitalize" }}
                  >
                    {options}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    What field should be used for the device's RAM storage?
                  </FormLabel>
                  <Select
                    placeholder="Please select your desired field"
                    name="storageRAM"
                    onChange={handleChange}
                    value={values.storageRAM}
                    style={{ textTransform: "capitalize" }}
                  >
                    {options}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    What field should be used for the device's name?
                  </FormLabel>
                  <Select
                    placeholder="Please select your desired field"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    style={{ textTransform: "capitalize" }}
                  >
                    {options}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    What field should be used for the device's price?
                  </FormLabel>
                  <Select
                    placeholder="Please select your desired field"
                    name="price"
                    onChange={handleChange}
                    value={values.price}
                    style={{ textTransform: "capitalize" }}
                  >
                    {options}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    Device's condition?
                  </FormLabel>
                  <Select
                    placeholder="Please select devices condition"
                    name="condition"
                    onChange={handleChange}
                    value={values.condition}
                    style={{ textTransform: "capitalize" }}
                  >
                    (
                      {
                        DEVICE_CONDITIONS.map(condition => (
                          <option
                            key={condition}
                            value={condition}
                            style={{ textTransform: "capitalize" }}
                          >
                          {condition}
                        </option>
                        ))
                      }
                    )
                  </Select>
                </FormControl>
              </Stack>
              <Center fontSize={"16px"} my={"1rem"} gap="1rem">
                <Button
                  color={"white"}
                  bg="var(--keza-brown)"
                  _hover={{
                    bg: "var(--keza-brown)"
                  }}
                  onClick={() => handleSubmit()}
                  isLoading={uploadBulkPricingResult.isLoading}
                >
                  Save
                </Button>
              </Center>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BulkUploadModal;
