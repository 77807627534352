import { useMutation ,useQuery} from "react-query";
import { loginVendor, registerVendor, forgotPasswordVendor, resetPasswordVendor } from "../api";
import { getAllBanks } from "../../../common/api/paystack";

export function useVendorLogin() {
  const { mutateAsync, ...loginVendorResult } = useMutation(loginVendor);
  return {
    loginVendor: mutateAsync,
    loginVendorResult
  };
}

export function useRegisterVendor() {
  const { mutateAsync, ...registerVendorResult } = useMutation(registerVendor);
  return {
    registerVendor: mutateAsync,
    registerVendorResult
  };
}

export function useGetAllBanks() {
    const { data, isLoading } = useQuery("requests", getAllBanks, {
      staleTime: 300000, // 5 minutes
      cacheTime: 3600000 // 1 hour
    });
    return {
      requestsRes: data,
      isLoading
    };
  }

export function useVendorForgotPassword() {
  const { mutateAsync, ...forgotPasswordVendorResult } = useMutation(forgotPasswordVendor);
  return {
    forgotPasswordVendor: mutateAsync,
    forgotPasswordVendorResult
  };
}

export function useVendorResetPassword() {
  const { mutateAsync, ...resetPasswordVendorResult } = useMutation(resetPasswordVendor);
  return {
    resetPasswordVendor: mutateAsync,
    resetPasswordVendorResult
  };
}