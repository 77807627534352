import { Product } from "@/interfaces/product.interface";

export interface Document {
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export enum VendorDeviceCondition {
  PRE_OWNED = "PRE_OWNED",
  NEW = "NEW"
}

export enum VendorDeviceModel {
  Redmi = "Redmi",
  Samsung = "Samsung",
  Oppo = "Oppo",
  iPhone = "iPhone",
  Tecno = "Tecno",
  Vivo = "Vivo",
  Huawei = "Huawei",
  Infinix = "Infinix"
}

export type BidType = {
  id: string;
  bid: number;
};

export type Application = {
  createdAt: string;
  disbursementStatus: string;
  isApproved: boolean;
  isDownPaymentMade: boolean;
  isPaymentMandateActive: boolean;
  meta: any;
  needsInsurance: boolean;
  owner: string;
  ownerDetail: any;
  passedCreditAndProfileCheck: boolean;
  phonCondition: string;
  phoneColor: string;
  phoneStorage: string;
  product: string;
  productDetail: { name: string };
  status: string;
  updatedAt: string;

  _id: string;
};

export type RequestType = {
  _id: string;
  isSold: boolean;
  application: Application;
  bid: number;
  isAccepted: boolean;
  createdAt: string;
  updatedAt: string;
};

export type RequestsResponse = {
  content: Array<RequestType>;
  pagination: {
    page: number;
    size: number;
    totalDocs: number;
    numberOfPages: number;
  };
};

export type RequestFilterType = {
  sold: boolean;
  pending: boolean;
  all: boolean;
};

export interface CreateVendorDto {
  firstName: string;

  lastName: string;

  phoneNumber: string;

  email: string;

  businessName: string;

  businessAddress: string;

  yearsInBusiness: number;

  password: string;
}

export interface UpdateVendorDto extends Partial<CreateVendorDto> {
  deviceConditions?: VendorDeviceCondition[];

  deviceModels?: VendorDeviceModel[];

  businessNumber?: string;

  accountNumber?: string;

  accountName?: string;

  bankName?: string;
  bankCode?: string;
}

export interface ApplicationDetails extends Document {
  product: Product;
  owner: Partial<OwnerDetails>;
  meta: IMeta;
  payment: Payment;
  ownerDetail?: OwnerDetails;
  productDetail?: ProductDetails;
  isApproved: boolean;
  needsInsurance: boolean;
  passedCreditAndProfileCheck: boolean;
  downPaymentAmount?: string;
  repaymentAmount?: string;
  disbursementStatus:
    | "DISBURSED"
    | "DISBURSMENT_FAILED"
    | "AWAITING_APPROVAL_LEVEL1"
    | "AWAITING_APPROVAL_LEVEL2"
    | "APPROVED_AWAITING_DISBURSEMENT"
    | "DECLINED";
  phonCondition: "PRE_OWNED" | "NEW";
  phoneStorage: string;
  status:
    | "APPROVED"
    | "PENDING"
    | "DECLINED"
    | "AWAITING_DISBURSMENT"
    | "DISBURSED"
    | "DELETED";
  rejectionReason?: string;
  phoneColor: string;
  isDownPaymentMade: boolean;
  isPaymentMandateActive: boolean;
  referredBy?: string;
  createdBy?: string;
  vendor?: string;
}

export interface OwnerDetails {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  bvn: string;
}

export interface ProductDetails {
  name: string;
}

export interface Payment {
  total: string;
  down: string;
  dividend: string;
  paid: string;
}

export interface IMeta {
  plan: string;
  duration: number;
}
