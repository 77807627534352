import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { TextInput } from "../Input";
import { useFormik } from "formik";
import * as yup from "yup";
import { useUpdateVendor } from "@/features/dashboard/operations";
import handleErrorMessage from "@/utilities/get-error";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { VendorState } from "@/features/auth/resources";
import { CustomModalProps } from "../UploadComponents/UploadCac";
import { useGetAllBanks } from "@/features/auth/operations";
import { useEffect, useState } from "react";
import { formatBank } from "@/utilities/format-bank";
import Progress from "../Progress";
import Select from "react-select";
import { useGetBankDetails } from "@/common/operations/account-details";
import { useNavigate } from "react-router-dom";

export const colorStyles = {
  control: (base: any, isFocused: any) => ({
    ...base,
    backgroundColor: "white",
    cursor: "pointer",
    borderRadius: "8px",
    border: "0.663653px solid #1D1D1D",
    fontSize: "0.9rem"
  })
};

export default function AccountModal({ isOpen, onClose }: CustomModalProps) {
  const [, setVendor] = useRecoilState(VendorState);
  const { isLoading, requestsRes } = useGetAllBanks();
  const { getBankDetails, getBankDetailsResult } = useGetBankDetails();
  const { updateVendor, updateVendorResult } = useUpdateVendor();
  const [accountName, setAccountName] = useState<string>("");
  const [bankDetails, setBankDetails] = useState<string>("");
  const [banks, setBanks] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (requestsRes) {
      setBanks(formatBank(requestsRes?.data?.data));
    }
  }, [requestsRes]);

  const validationSchema = yup.object().shape({
    accountNumber: yup
      .string()
      .optional()
      .length(10, "Please provide valid account number"),
    bankCode: yup.string().required("Please select your bank")
  });

  const { values, errors, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: {
        bankCode: "",
        accountNumber: ""
      },
      validationSchema,
      onSubmit: async values => {
        if (!Boolean(isValid)) {
          return;
        }
        try {
          const res = await updateVendor({
            accountName,
            bankName: bankDetails?.split("|")[1],
            bankCode: bankDetails?.split("|")[0],
            accountNumber: values.accountNumber
          });
          if (res.data.accountNumber) {
            setVendor(res.data);
            toast("Operation Successful", { type: "success" });
            onClose();
          }
        } catch (error) {
          handleErrorMessage(error);
        }
      }
    });

  useEffect(() => {
    if (Boolean(values?.bankCode) && values?.accountNumber?.length === 10) {
      getBankDetails({
        accountNumber: values.accountNumber,
        bankCode: values.bankCode
      })
        .then((data: any) => {
          setAccountName(data?.data.account_name);
        })
        .catch(err => {
          toast("Couldn't fetch account", { type: "error" });
        });
    } else {
      setAccountName("");
    }
  }, [values]);

  const isValid =
    accountName && values?.accountNumber?.length === 10 && bankDetails;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={["95%", ""]}>
        <ModalCloseButton />
        <ModalBody p={{ base: "2rem 1rem", md: "3rem 2rem" }}>
          <Text fontWeight={"600"} color={"var(--keza-brown)"}>
            Add account
          </Text>
          <Text opacity={"0.5"} fontSize={{ base: "14px", md: "16px" }}>
            Fill in your account details to receive your monthly payments
          </Text>

          <Box my={"2rem"}>
            <TextInput
              label="Account number"
              name="accountNumber"
              onChange={handleChange}
              value={values.accountNumber}
            />
            {errors.accountNumber && (
              <small className="small-txt">{errors?.accountNumber}</small>
            )}
          </Box>
          <Box>
            <Select
              name={"channel"}
              options={banks}
              styles={colorStyles}
              className="my-select-cont"
              classNamePrefix={"my-select"}
              placeholder="Choose bank"
              onChange={(event: any) => {
                setFieldValue("bankCode", event.value.split("|")[0]);
                setBankDetails(event.value);
              }}
              id="bank"
            />{" "}
            {errors.bankCode && (
              <small className="small-txt">{errors?.bankCode}</small>
            )}
          </Box>

          <Box my={"2rem"}>
            <TextInput
              label="Account name"
              name="accountName"
              value={accountName}
              readOnly={true}
              onChange={handleChange}
            />
          </Box>

          <Center mt={"2rem"}>
            <Button
              colorScheme={"var(keza-brown)"}
              onClick={() => handleSubmit()}
              disabled={!isValid}
              bg={isValid ? "var(--keza-brown)" : "rgba(29, 29, 29, 0.1)"}
              color={isValid ? "white" : ""}
              isLoading={
                getBankDetailsResult.isLoading || updateVendorResult.isLoading
              }
            >
              {getBankDetailsResult.isLoading ? "Loading..." : "Save"}
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
      <Progress loading={isLoading} />
    </Modal>
  );
}
