const Button = {
  sizes: {
    sm: {
      fontSize: "md",
      width: "180px",
      height: "40px",
      px: 2.5,
      py: 2,
      borderRadius: ""
    },
    md: {
      fontSize: "md",
      width: "215px",
      height: "48px",
      px: 3.5,
      py: 4,
      borderRadius: "0.625em"
    },
    lg: {
      fontSize: "lg",
      width: "236px",
      height: "52px",
      px: 4,
      py: 4,
      borderRadius: "0.625em"
    }
  },
  variants: {
    primary: {
      color: "brand.grey.100",
      bgColor: "brand.primary.500",
      _focus: {
        boxShadow:
          "28px 40px 20px rgba(24, 19, 162, 0.03), 16px 23px 17px rgba(24, 19, 162, 0.1), 7px 10px 12px rgba(24, 19, 162, 0.17), 2px 3px 7px rgba(24, 19, 162, 0.2), 0px 0px 0px rgba(24, 19, 162, 0.2)",
        bgColor: "brand.primary.500",
        borderRadius: "10px"
      },
      _hover: {
        border: "8px solid #B7B6E2",
        boxShadow: " 0px 0px 27px rgba(24, 19, 162, 0.2)",
        bgColor: "brand.primary.500",
        borderRadius: "10px"
      },
      _visited: {
        bgColor: "brand.secondary.500"
      }
    },
    light: {
      fontWeight: "400",
      h: "2rem",
      variant: "outline",
      bg: "transparent",
      borderRadius: "1000px",
      border: "1px solid black",

      _hover: {
        bgColor: "transparent"
      },

      _visited: {
        bgColor: "brand.primary.100"
      }
    }
  }
};

export default Button;
