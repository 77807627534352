import "./styles/App.css";
import { createContext } from "react";
import AppProvider from "./providers/app";
import { AppRoutes } from "./routes";
//import Login from "./pages/Login/Login";

export const MenuContext = createContext<boolean | any>(null);

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
