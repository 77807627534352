import { Box, Text } from "@chakra-ui/react";
import React from "react";
import "./index.css";

type StepsProps = {
  currentStep: number;
  step: number;
  value: string;
  formCompleted: boolean;
  setStep: any;
};
export default function CustomStep({
  step,
  value,
  currentStep,
  formCompleted,
  setStep
}: StepsProps) {
  function colorSteps(number: number) {
    if (formCompleted) {
      return "formm-done-brown";
    }

    const color =
      currentStep >= number
        ? "var(--keza-brown)"
        : step > number
        ? "var(--keza-brown)"
        : "";
    return color;
  }

  return (
    <div className="st-tab">
      <Box
        className="tab-circle"
        onClick={() => (currentStep === 2 ? setStep(step) : "")}
        color={colorSteps(step)}
        opacity={currentStep === step ? 1 : "0.7"}
        border={`1px solid ${colorSteps(step)}`}
      >
        {step}
      </Box>
      <Text
        color={colorSteps(step)}
        fontSize={{ base: "14px", md: "16px" }}
        className="steps-description"
      >
        {value}
      </Text>
    </div>
  );
}
