import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";

import "./index.css";
import SideBar from "./SideBar";
import Header from "./Header";
import { ProductRequestState } from "../resources/vendor";
import { useGetRequest } from "../operations";
import Progress from "@/components/Progress";

export default function Layout() {
  const [, setProductRequests] = useRecoilState(ProductRequestState);

  const { getRequests, getRequestsResult } = useGetRequest();

  useEffect(() => {
    getRequests().then(result => {
      if (result) {
        setProductRequests(result);
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div className="dash-back">
      <Header />
      <Flex pt={"4rem"} mt={"1rem"} gap={"0"} justifyContent={"space-between"}>
        <SideBar />
        <Outlet />
      </Flex>

      <Progress loading={getRequestsResult.isLoading} />
    </div>
  );
}
