import { Box, Flex, Text } from "@chakra-ui/react";
import wave from "@/assets/dashboard/wave.png";
import { useRecoilValue } from "recoil";
import { VendorState } from "@/features/auth/resources";

export default function SectionHeader() {
  const vendor = useRecoilValue(VendorState);

  return (
    <>
      <Flex justifyContent={"space-between"}>
        <Box>
          <Flex alignItems={"center"} gap={["0.6rem"]}>
            <Text
              textTransform="capitalize"
              fontSize={["22px", "24px"]}
              fontFamily="BR Firma"
            >
              Hey {vendor?.business?.name}
            </Text>
            <Box maxW={"1.5rem"}>
              <img src={wave} alt="wave" />
            </Box>
          </Flex>
          <Text fontSize={"14px"} opacity="0.7">
            Check out your new requests{" "}
          </Text>
        </Box>
      </Flex>
      <Box
        w={"100%"}
        h="1px"
        bg={"black"}
        opacity="0.3"
        my={["0.5rem", "1.5rem"]}
      ></Box>
    </>
  );
}
