import { toast } from "react-toastify";
export async function copy(text: string) {
  if (window.navigator) {
    try {
      await window.navigator.clipboard.writeText(text);
      toast("Copied to Clipboard!", { type: "success" });
    } catch (err) {
      toast("An Error occurred", { type: "error" });
    }
  }
}
