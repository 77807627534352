import { Center, Image, Text } from "@chakra-ui/react";
import "./index.css";

import { RxDashboard } from "react-icons/rx";
import { TbNotes } from "react-icons/tb";
import { FiDatabase, FiLogOut } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import { NavItem } from "./SideBar";
import { useRecoilState } from "recoil";
import { VendorState } from "@/features/auth/resources";
import { AiOutlineTag } from "react-icons/ai";
import { MdNotes } from "react-icons/md";

type MobileMenuProps = {
  setShowMenu: Function;
};

export default function MobileMenu({ setShowMenu }: MobileMenuProps) {
  const [vendor] = useRecoilState(VendorState);

  return (
    <div className="mobile-men">
      <Center
        bg="#F3F3F3"
        borderRadius="1000px"
        w={["6rem", "6rem"]}
        h={["6rem", "6rem"]}
        mx={"auto"}
      >
        <Image
          w={"4rem"}
          h="4rem"
          borderRadius={"50%"}
          src={vendor.business.logo}
          alt="avatar"
        />
      </Center>
      <Center p="0.7rem">
        <Text
          textAlign={"center"}
          textTransform="capitalize"
          fontWeight={"600"}
          fontFamily="BR Firma"
        >
          {vendor.business.name}
        </Text>
      </Center>
      <Center mt={"2rem"}>
        <nav className="sb-nav-wrapper">
          <ul
            onClick={() => {
              setShowMenu(false);
            }}
          >
            <NavItem
              name="Dashboard"
              path="/dashboard"
              IconPath={RxDashboard}
              // onClick={handleMenuOpen}
            />
            <NavItem
              name="Product requests"
              path="requests"
              IconPath={TbNotes}
              // onClick={handleMenuOpen}
            />
            <NavItem
              name="My inventory"
              path="inventory"
              IconPath={AiOutlineTag}
              // onClick={handleMenuOpen}
            />
            <NavItem
              name="Applications"
              path="applications"
              IconPath={MdNotes}
              // onClick={handleMenuOpen}
            />
            <NavItem
              name="Profile"
              path="profile"
              IconPath={FaRegUser}
              // onClick={handleMenuOpen}
            />
            <NavItem
              name="Uploads"
              path="uploads"
              IconPath={FiDatabase}
              // onClick={handleMenuOpen}
            />

            <div style={{ marginTop: "4rem" }}>
              <NavItem
                name="Sign out"
                IconPath={FiLogOut}
                // onClick={handleMenuOpen}
              />
            </div>
          </ul>
        </nav>
        <br />
      </Center>
    </div>
  );
}
