import { resolveAccount } from "@/features/dashboard/api";
import { useMutation } from "react-query";
// import { resolveAccount } from "../api/paystack";

export function useGetBankDetails() {
  const { mutateAsync, ...getBankDetailsResult } = useMutation(resolveAccount);
  return {
    getBankDetails: mutateAsync,
    getBankDetailsResult
  };
}
