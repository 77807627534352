import AuthRoutes from "@/features/auth/routes";
import DashboardRoute from "@/features/dashboard/routes";
import { useRoutes } from "react-router-dom";

export function AppRoutes() {
  const commonRoutes = [
    {
      path: "/*",
      element: <DashboardRoute />
    },
    {
      path: "/auth/*",
      element: <AuthRoutes />
    }
  ];

  const element = useRoutes([...commonRoutes]);

  return <>{element}</>;
}
