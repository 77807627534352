import RequestTable from "@/components/RequestComponents/RequestTable";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { ProductRequestState } from "../resources/vendor";
import { RequestFilterType } from "../types";
import SectionHeader from "./SectionHeader";

export default function Request() {
  const [reqFilter, setReqFilter] = useState<RequestFilterType>({
    all: true,
    sold: false,
    pending: false
  });
  const productRequests = useRecoilValue(ProductRequestState);

  const getColor = useCallback((val: boolean) => {
    if (val) {
      return "var(--keza-brown)";
    }
    return "";
  }, []);

  return (
    <div className="dash-child">
      <SectionHeader />

      <Box>
        <Text fontSize={"24px"} fontWeight="600" fontFamily="BR Firma">
          Product requests
        </Text>
      </Box>

      <Box my={"2rem"}>
        <Flex
          alignItems={"center"}
          gap={{ base: "1rem", md: "2rem" }}
          fontSize={{ base: "14px", md: "" }}
        >
          <Button
            variant={"light"}
            size="sm"
            opacity={"0.7"}
            w={{ base: "6rem", md: "9rem" }}
            h={{ base: "2rem", md: "" }}
            color={getColor(reqFilter.all)}
            borderColor={getColor(reqFilter.all)}
            fontSize={{ base: "12px", md: "" }}
            onClick={() =>
              setReqFilter({ pending: false, sold: false, all: true })
            }
          >
            All
            <span style={{ marginLeft: "1rem", color: "#E93B14" }}>
              ({productRequests?.content?.length || 0})
            </span>
          </Button>
          <Button
            borderColor={getColor(reqFilter.pending)}
            color={getColor(reqFilter.pending)}
            variant={"light"}
            size="sm"
            w={{ base: "6rem", md: "9rem" }}
            h={{ base: "2rem", md: "" }}
            fontSize={{ base: "12px", md: "" }}
            onClick={() =>
              setReqFilter({ pending: true, sold: false, all: false })
            }
          >
            Pending
            <span
              style={{
                marginLeft: "1rem",
                color: `${reqFilter.pending ? "#E93B14" : ""}`
              }}
            >
              (
              {productRequests?.content?.filter(req => req.bid > 0)?.length ||
                0}
              )
            </span>
          </Button>
          <Button
            w={{ base: "6rem", md: "9rem" }}
            h={{ base: "2rem", md: "" }}
            fontSize={{ base: "12px", md: "" }}
            color={getColor(reqFilter.sold)}
            borderColor={getColor(reqFilter.sold)}
            variant={"light"}
            size="sm"
            onClick={() =>
              setReqFilter({ pending: false, sold: true, all: false })
            }
          >
            Sold{" "}
            <span
              style={{
                marginLeft: "1rem",
                color: `${reqFilter.sold ? "#E93B14" : ""}`
              }}
            >
              {`(${
                productRequests?.content?.filter(request => !!request.isSold)
                  ?.length || 0
              })`}
            </span>
          </Button>
        </Flex>

        <RequestTable filter={reqFilter} />
      </Box>
    </div>
  );
}
