import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import { SlCloudUpload } from "react-icons/sl";
import { FiAlertTriangle } from "react-icons/fi";
import { useUploadDocument } from "@/features/dashboard/operations";
import handleErrorMessage from "@/utilities/get-error";
import { useRecoilState } from "recoil";
import { VendorState } from "@/features/auth/resources";
import { toast } from "react-toastify";

export type CustomModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
export default function UploadCac({ isOpen, onClose }: CustomModalProps) {
  const [, setVendor] = useRecoilState(VendorState);
  const { uploadDocument, uploadDocumentResult } = useUploadDocument();

  const [document, setDocument] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  async function uploadRawDocument(e: any) {
    if (document) {
      try {
        const form = new FormData();

        form.append("document", document, document.name);

        const data = await uploadDocument(form);

        if (data) {
          setVendor(data.data);
          toast((data as any).message, { type: "success" });
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={["95%", ""]}>
        <ModalCloseButton />
        <ModalBody p={{ base: "2rem 1rem", md: "3rem 2rem" }}>
          <Text fontWeight={"700"} color={"var(--keza-brown)"}>
            Upload CAC document
          </Text>
          <Text mt="0.5rem" mb={"3rem"} opacity={"0.5"} fontSize="15px">
            A CAC document typically refers to the legal registration documents
            required for incorporating your business.
          </Text>
          <Center cursor={"pointer"}>
            <form>
              <div>
                <Center
                  flexDirection={"column"}
                  borderRadius="8px"
                  gap={"1.5rem"}
                  border="0.663653px solid rgba(29, 29, 29)"
                  opacity={"0.5"}
                  w="20rem"
                  h={"10rem"}
                  onClick={() => {
                    if (fileInputRef?.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  <Flex alignItems={"center"} gap="1.5rem">
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept=".pdf"
                      hidden
                      onChange={e => {
                        if (e.target.files?.length && e.target.files?.[0]) {
                          setDocument(e.target?.files[0] as File);
                        }
                      }}
                    />
                    <Box maxW={"2rem"}>
                      <SlCloudUpload />
                    </Box>
                    <p className="cam-p">
                      {document ? `${document.name}` : "Attach a document"}
                    </p>
                  </Flex>
                  <Text fontSize={"12px"}>file types should be .pdf, .png</Text>
                </Center>
                <Center
                  gap={"1rem"}
                  my="1rem"
                  color="#E50917"
                  fontSize={"10px"}
                >
                  <FiAlertTriangle />
                  <Text>
                    Ensure this file is not locked or password protected
                  </Text>
                </Center>
              </div>
              <Flex alignItems={"center"} justifyContent={"center"} mt={"1rem"}>
                <Button
                  isLoading={uploadDocumentResult.isLoading}
                  id="auth-btn"
                  onClick={uploadRawDocument}
                  className="form-btn brwn-btn"
                >
                  Upload
                </Button>
              </Flex>
            </form>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
