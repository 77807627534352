import { ProductRequestState } from "@/features/dashboard/resources/vendor";
import { RequestFilterType, RequestType } from "@/features/dashboard/types";
import { getStatus } from "@/utilities/get-status";
import {
  Box,
  Center,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Button
} from "@chakra-ui/react";
import moment from "moment";
import { IoOptionsSharp } from "react-icons/io5";
import { useRecoilValue } from "recoil";

export default function RequestTable({
  filter
}: {
  filter: RequestFilterType;
}) {
  const productRequests = useRecoilValue(ProductRequestState);

  return (
    <Box
      mt={"2rem"}
      border="0.5px solid rgba(29, 29, 29, 0.5)"
      borderRadius="5px"
      w={"100%"}
    >
      <Flex
        alignItems={"center"}
        justifyContent="space-between"
        padding={"1rem 1.5rem"}
      >
        <Text fontSize={"14px"}>
          Showing {productRequests?.content?.length} of{" "}
          {productRequests?.pagination?.totalDocs} results
        </Text>
        <Center
          cursor={"pointer"}
          gap={"2rem"}
          padding="0.5rem"
          borderRadius={"6px"}
          border="0.5px solid rgba(29, 29, 29, 0.5)"
        >
          <Text display={{ base: "none", md: "block" }}>Filter by</Text>
          <IoOptionsSharp />
        </Center>
      </Flex>
      <TableContainer w={"100% !important"} overflowX="auto">
        <Table
          variant="simple"
          borderRadius={"10px"}
          fontSize={"18px"}
          size={"sm"}
        >
          <Thead bg={"#EDEDED"}>
            <Tr
              fontFamily={"DM Sans"}
              fontWeight="14px"
              opacity={"0.7"}
              fontSize="14px"
            >
              <Td>Date / Time</Td>
              <Td>Device name</Td>
              <Td>RAM / ROM</Td>
              <Td>Device condition</Td>
              <Td>Status</Td>
            </Tr>
          </Thead>
          <Tbody fontSize="16px">
            {productRequests?.content
              ?.filter(request => {
                if (filter.pending) {
                  return request.bid > 0;
                } else if (filter.sold) {
                  return request.isSold;
                } else return true;
              })
              ?.map((request: RequestType, index) => {
                return (
                  <Tr key={index} bg={"#FFF7F4"}>
                    <Td>
                      <Box>
                        <Text>
                          {moment(String(request?.createdAt)).format(
                            "Do [of] MMMM, YYYY"
                          )}
                        </Text>
                        <Text my={"0.4rem"} fontSize="12px" opacity={"0.6"}>
                          {moment(request?.createdAt).format("h:mm A")}
                        </Text>
                      </Box>
                    </Td>
                    <Td>
                      <Box>
                        <Text>{request?.application?.productDetail?.name}</Text>
                        <Text my={"0.4rem"} fontSize="12px" opacity={"0.6"}>
                          {request?.application?.phoneColor}
                        </Text>
                      </Box>
                    </Td>
                    <Td> {request?.application?.phoneStorage}</Td>
                    <Td textTransform={"capitalize"}>
                      {request?.application?.phonCondition.toLowerCase()}
                    </Td>
                    <Td>
                      <Button
                        w={"150px"}
                        borderRadius={"7px"}
                        variant={"light"}
                      >
                        {getStatus(request.bid, request.isAccepted)}
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
