import { Box, Center, Flex, Text } from "@chakra-ui/react";
import React from "react";
import note from "@/assets/dashboard/note.png";
import { BsDot } from "react-icons/bs";

export default function AcceptBanner() {
  return (
    <Center
      bg="rgba(207, 224, 235, 0.2)"
      boxShadow="0px 2px 15px 5px rgba(29, 29, 29, 0.08)"
      borderRadius="10px"
      flexDirection={"column"}
    >
      <Box maxW={"50%"} mx="auto">
        <img src={note} alt="note" />
      </Box>
      <Text color={"var(--keza-brown)"} fontWeight="700" my={"1rem"}>
        How to accept product requests
      </Text>
      <Box mx={"auto"} maxW={{ base: "90%", md: "70%" }}>
        <Flex mb={"1rem"} alignItems={"center"}>
          <BsDot width={"2rem"} color="var(--keza-brown)" />
          <Text fontSize={{ base: "14px", md: "16px" }} align={"center"}>
            Click on the “Place offer” button
          </Text>
        </Flex>
        <Flex mb={"1rem"} alignItems={"flex-start"}>
          <BsDot width={"2rem"} color="var(--keza-brown)" />
          <Text fontSize={{ base: "14px", md: "16px" }} align={"center"}>
            Provide the amount for the device you want to sell
          </Text>
        </Flex>
        <Flex mb={"1rem"} alignItems={"flex-start"}>
          <BsDot width={"2rem"} color="var(--keza-brown)" />
          <Text fontSize={{ base: "14px", md: "16px" }} align={"center"}>
            If product is available click “Submit offer”
          </Text>
        </Flex>

        <Text my={"2rem"} fontSize={"12px"} opacity="0.6" align={"center"}>
          If your offer is accepted, you will be notified via Whatsapp
        </Text>
      </Box>
    </Center>
  );
}
