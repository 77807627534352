import { TextInput } from "@/components/Input";
import {
  Box,
  Button,
  Center,
  FormControl,
  Grid,
  GridItem,
  Text
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useRecoilState, useResetRecoilState } from "recoil";
import { VendorSignUpState } from "../resources";
import { VendorRegisterAtom } from "../types";
import * as Yup from "yup";
import { useRegisterVendor } from "../operations";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function BusinessInfo() {
  const [
    {
      business: { ...initialValues },
      personal
    }
  ] = useRecoilState(VendorSignUpState);
  const resetVendorSignUpState = useResetRecoilState(VendorSignUpState);
  const { registerVendor, registerVendorResult } = useRegisterVendor();
  const navigate = useNavigate();
  const { values, handleChange, submitForm, errors, touched } = useFormik<
    VendorRegisterAtom["business"]
  >({
    initialValues,
    onSubmit: async () => {
      try {
        const res = await registerVendor({
          ...personal,
          ...values,
          yearsInBusiness: String(values.yearsInBusiness)
        });
        if (res) {
          toast("Account has been successfully created ", { type: "success" });
        }
        resetVendorSignUpState();
        navigate("/dashboard");
      } catch (err) {}
    },
    validationSchema: Yup.object().shape({
      businessName: Yup.string().required().min(3),
      businessAddress: Yup.string().required().min(3),
      yearsInBusiness: Yup.number().required().min(1)
    })
  });
  return (
    <Box my={"2rem"}>
      <Text
        fontSize={{ base: "16px", md: "18px" }}
        mb={"0.5rem"}
        opacity={"0.5"}
      >
        Personal details
      </Text>
      <Text fontSize={{ base: "14px", md: "16px" }} color="var(--keza-brown)">
        We need some key business details to verify you
      </Text>

      <FormControl my={"2rem"}>
        <Grid
          rowGap={"2rem"}
          columnGap="2rem"
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={6}
        >
          <GridItem>
            <TextInput
              name="businessName"
              onChange={handleChange}
              label={"Business name"}
              value={values.businessName}
              error={errors.businessName}
              touched={touched.businessName}
              errorMessage={"Please input your Business name"}
            />
          </GridItem>
          <GridItem>
            <TextInput
              name="businessAddress"
              onChange={handleChange}
              label={"Business address"}
              value={values.businessAddress}
              error={errors.businessAddress}
              touched={touched.businessAddress}
              errorMessage={"Please input your Business address"}
            />
          </GridItem>
          <GridItem>
            <TextInput
              name="yearsInBusiness"
              onChange={handleChange}
              label={"How long has your business been active?"}
              type="number"
              placeHolder="in years"
              error={errors.yearsInBusiness}
              touched={touched.yearsInBusiness}
              errorMessage={"How long have you been a vendor"}
            />
          </GridItem>
        </Grid>
      </FormControl>

      <Center>
        <Button
          color="white"
          bg="var(--keza-brown)"
          size={"lg"}
          _hover={{
            bg: "rgba(29, 29, 29, 0.1);"
          }}
          w={{ base: "15rem" }}
          onClick={submitForm}
          isLoading={registerVendorResult.isLoading}
        >
          Create account
        </Button>
      </Center>
    </Box>
  );
}

// type BusinessInfoDto = {
//   businessName: string;
//   businessAddress: string;
//   howLong: number;
// };
