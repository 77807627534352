import AcceptBanner from "@/components/DashboardComponents/AcceptBanner";
// import BalanceCard from "@/components/DashboardComponents/BalanceCard";
import RequestTab from "@/components/DashboardComponents/RequestTab";
import DashboardTable from "@/components/DashboardComponents/DashboardTable";
import { Box, Flex, Text } from "@chakra-ui/react";
import SectionHeader from "./SectionHeader";
import { useRecoilState, useRecoilValue } from "recoil";
import { ProductRequestState } from "../resources/vendor";
import { VendorState } from "@/features/auth/resources";
import { copy } from "@/utilities/copy-to-clipboard";
import { toast } from "react-toastify";
import AccountCard from "@/components/DashboardComponents/AccountCard";

export default function Dashboard() {
  const [vendor] = useRecoilState(VendorState);
  const productRequests = useRecoilValue(ProductRequestState);

  async function shareLink() {
    if (vendor.isActive) {
      const url = `https://keza.africa/partners/${vendor?.username}`;
      if (window.navigator) {
        try {
          await window.navigator.share({
            url,
            title: "Share Link"
          });
        } catch (error) {
          await copy(url);
        }
      } else {
        toast(`An error occured`, { type: "error" });
      }
    }
  }

  return (
    <div className="dash-child">
      <SectionHeader />

      {vendor.isActive && (
        <Flex
          fontSize={{ base: "10px", md: "14px" }}
          my="1.5rem"
          justifyContent={"space-between"}
          p={"0.5rem 1rem"}
          bg="rgba(170,68,40,0.1)"
          borderRadius={"4px"}
          maxW="30rem"
        >
          <Text fontSize={"xs"}>
            {`https://keza.africa/partners/${vendor?.username}`}
          </Text>
          <Text
            cursor={"pointer"}
            onClick={shareLink}
            color={"var(--keza-brown)"}
          >
            Share link
          </Text>
        </Flex>
      )}

      <Flex
        gap={"2rem"}
        justifyContent={"space-between"}
        direction={{ base: "column", lg: "row" }}
        maxW="100vw"
      >
        <Flex direction={"column"} gap="2rem">
          <RequestTab
            noOfRequests={productRequests?.content?.length}
            noOfSold={
              productRequests?.content?.filter(request => !!request.isSold)
                .length
            }
          />
          <Box display={{ base: "none", md: "block" }}>
            <DashboardTable />
          </Box>
          <Box display={{ base: "block", md: "none" }}>
            <AccountCard />
          </Box>
        </Flex>
        <Flex direction={"column"} gap="3rem">
          <Box display={{ base: "none", md: "block" }}>
            <AccountCard />
          </Box>
          <Box display={{ base: "block", md: "none" }}>
            <DashboardTable />
          </Box>
          <AcceptBanner />
        </Flex>
      </Flex>
    </div>
  );
}
