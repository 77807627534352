import { Box, Center, Divider, Text } from "@chakra-ui/react";
import { useState } from "react";
import BusinessInfo from "./BusinessInfo";
import PersonalDetails from "./PersonalDetails";
import CustomStep from "./Step";
import line from "@/assets/auth/Vector 73line.png";

export default function SignUp() {
  const [step, setStep] = useState(1);
  const [formCompleted, setFormCompleted] = useState(false);

  const props = {
    step,
    setStep,
    formCompleted,
    setFormCompleted
  };
  return (
    <>
      <Text fontWeight={"700"} fontSize="18px" color={"var(--keza-brown)"}>
        Join Keza Africa as a vendor
      </Text>
      <Text fontSize={"14px"}>
        Sign up as a smartphone vendor with Keza Africa
      </Text>
      {/* ============================================================================ */}
      <Center
        mt={{ base: "1.5rem", md: "3rem" }}
        mb={{ base: "3rem", md: "3rem" }}
      >
        <Box className="steps">
          <CustomStep
            step={1}
            currentStep={step}
            setStep={setStep}
            formCompleted={formCompleted}
            value={"Personal details"}
          />
          <div>
            <img src={line} alt="line" />
          </div>
          <CustomStep
            step={2}
            currentStep={step}
            setStep={setStep}
            formCompleted={formCompleted}
            value={"Business info"}
          />
        </Box>
      </Center>
      <Box
        className="line"
        bg="black"
        w={"100%"}
        h="0.1px"
        opacity={"0.3"}
        mt={{ base: "0.1rem", md: "4rem" }}
      ></Box>
      <Box>
        {step === 1 ? (
          <PersonalDetails prop={{ ...props }} />
        ) : (
          <BusinessInfo /** props={{...props}} */ />
        )}
      </Box>
      <Divider borderColor={"rgba(29, 29, 29, 0.5)"} my="2rem" />
    </>
  );
}
