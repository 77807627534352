import { Box, Button, Center, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

type ReqTabItemProps = {
  title: string;
  value?: number;
  color: string;
};
function ReqTabItem({ title, value, color }: ReqTabItemProps) {
  return (
    <Box p={{ base: "1rem", lg: "2rem" }}>
      <Flex
        alignItems={"center"}
        gap={"1rem"}
        direction={{ base: "column", md: "row" }}
      >
        <Text opacity={"0.5"} fontSize={{ base: "14px", md: "" }}>
          {title}
        </Text>
        <Text
          fontFamily="BR Firma"
          fontWeight={"600"}
          fontSize={{ base: "22px", md: "36px" }}
          color={color}
        >
          {value}
        </Text>
      </Flex>

      <Center mt="1rem">
        <Button
          rightIcon={<MdOutlineKeyboardArrowRight />}
          fontWeight="400"
          variant="outline"
          bg={"transparent"}
          borderRadius="1000px"
          borderColor={"black"}
          _hover={{
            bg: "transparent"
          }}
          w={{ base: "6rem", md: "" }}
          h={{ base: "2rem", md: "" }}
        >
          See all
        </Button>
      </Center>
    </Box>
  );
}

type ReqTabProps = {
  noOfRequests?: number;
  noOfSold?: number;
};
export default function RequestTab({ noOfRequests, noOfSold }: ReqTabProps) {
  return (
    <Flex
      px={{ base: "0rem", lg: "2rem" }}
      justifyContent={"space-between"}
      gap={{ base: "0rem", lg: "1rem" }}
      alignItems="center"
    >
      <ReqTabItem
        title="Product requests:"
        value={noOfRequests}
        color="#E04040"
      />

      <Divider
        h={"3rem"}
        orientation="vertical"
        borderColor={"rgba(0,0,0,0.5)"}
      />
      <ReqTabItem title="Products sold:" value={noOfSold} color="#1D1D1D" />
    </Flex>
  );
}
