import { CustomModalProps } from "./UploadCac";
import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { TextInput } from "../Input";
import { useFormik } from "formik";
import * as yup from "yup";
import { useUpdateVendorUsername } from "@/features/dashboard/operations";
import handleErrorMessage from "@/utilities/get-error";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { VendorState } from "@/features/auth/resources";

export default function ChangeUsername({ isOpen, onClose }: CustomModalProps) {
  const [vendor, setVendor] = useRecoilState(VendorState);

  const { updateVendorUsername, updateVendorUsernameResult } =
    useUpdateVendorUsername();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: { username: vendor.username },
      validationSchema: yup.object().shape({
        username: yup.string().required("Please provide your username")
      }),
      onSubmit: async values => {
        try {
          const data = (await updateVendorUsername(values.username)) as any;

          if (data) {
            setVendor(data.data);
            toast(data?.message, { type: "success" });
          }
        } catch (error) {
          handleErrorMessage(error);
        }
      }
    });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={["95%", ""]}>
        <ModalCloseButton />
        <ModalBody p={{ base: "2rem 1rem", md: "3rem 2rem" }}>
          <Text fontWeight={"600"} color={"var(--keza-brown)"}>
            Change Username
          </Text>
          <Text fontSize={"15"} opacity="0.5">
            Username
          </Text>

          <Box my={"4rem"}>
            <TextInput
              label="Username"
              name="username"
              onChange={handleChange}
              value={values.username}
              isInvalid={!!errors.username}
              error={!!errors.username}
              onBlur={handleBlur}
              touched={touched.username}
              errorMessage={errors.username}
            />
          </Box>

          <Center>
            <Button
              colorScheme={"var(keza-brown)"}
              onClick={() => handleSubmit()}
              color={"white"}
              bg="var(--keza-brown)"
              isLoading={updateVendorUsernameResult.isLoading}
            >
              Save changes
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
